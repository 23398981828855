import React, { FC } from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';

interface CardPropsPreview {
    title: string
    description: string
    price: any
    image: any
}

const ProductCardPreview:FC<CardPropsPreview> = ({ title, description, price, image }) => {
  return (
    <Card sx={{ width: 250 }}>
      <CardMedia
        sx={{ height: 140, width: '50%', marginLeft: '60px' }}
        image={ image }
        title="new-product"
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div" sx={{ wordBreak: 'break-word' }} >
          {title}
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{ wordBreak: 'break-word' }} >
            { description }
        </Typography>
      </CardContent>
      <CardActions>
        <Typography variant="h4" color="text.secondary">
          { price }
        </Typography>
      </CardActions>
    </Card>
  );
}

export default ProductCardPreview