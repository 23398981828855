import React from 'react';
import '../../styles/Users.sass';
import {getOrgs, getOrgUsers} from '../../../service/api';
import { Dropdown } from 'semantic-ui-react';
import {Row, Col, NavDropdown} from 'react-bootstrap';
import { Scrollbars } from 'react-custom-scrollbars';
import EditUserAdminModal from '../../modals/EditUserAdminModal';
import {FaEllipsisV} from 'react-icons/fa';
import CourtesyPoints from "./CourtesyPoints";

class Users extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            orgs: [],
            currentOrg: null,
            users: [],
            showEdit: false,
            editUser: {}
        }
        this.fetchOrgs = this.fetchOrgs.bind(this);
        this.getOrgOptions = this.getOrgOptions.bind(this);
        this.handleOrgChange = this.handleOrgChange.bind(this);
        this.fetchUsers = this.fetchUsers.bind(this);
        this.renderUsers = this.renderUsers.bind(this);
        this.handleEditClose = this.handleEditClose.bind(this);
    }

    componentDidMount = async () => {
        this.fetchOrgs()
    }

    fetchOrgs  = async () => {
        const orgs = await getOrgs()
        this.setState({orgs})
    }

    getOrgOptions = () => {
        const {orgs} = this.state;
        return orgs.map((org, index) => {
            return {
                key: index,
                text: org.name,
                value: org.id
            }
        })
    }

    handleOrgChange = (e, {value}) => {
        const {currentOrg} = this.state;
        if (currentOrg === value) return
        this.setState({currentOrg: value, users:[]})
        this.fetchUsers(value)
    }

    fetchUsers = (value) => {
        setTimeout(() => {
            if (!value) {
                value = this.state.currentOrg
            }
            getOrgUsers(value).then(users => this.setState({users}))
        }, 200)
    }

    handleEditClick = (user) => {
        this.setState({editUser: user, showEdit: true})
    }

    handleEditClose = () => {
        this.setState({editUser: {}, showEdit: false})
    }

    renderCourtesy = () => {
        return(
            <Row className="zeroMargin usersTableRow">
                <Col className="usersNameCol pl-z pr-s">
                    No users
                </Col>
            </Row>
        )
    }

    renderUsers = () => {
        const {users} = this.state;
        return users.map((user, index) => {
            return(
                <Row key={index} className="zeroMargin usersTableRow">
                    <Col className="usersNameCol pl-z pr-s">
                        {`${user.name} ${user.lastname}`}
                    </Col>
                    <Col className="usersEmailColE pl-z pr-s">
                        {`${user.emails}`}
                    </Col>
                    <Col className="usersAdminCol pl-z pr-s">
                        {user.is_admin ? 'True' : 'False'}
                    </Col>
                    <Col className="usersActiveCol pl-z pr-s">
                        {user.is_active ? 'True' : 'False'}
                    </Col>
                    <Col className="usersActiveCol pl-z pr-s">
                        {user.is_manager ? 'True' : 'False'}
                    </Col>
                        <NavDropdown className="productsTableDropDown" drop="down" title={
                            <FaEllipsisV className="hCentered"/>
                        }>
                            <Row className="ml-l pointer mb-s">
                                <span onClick={this.handleEditClick.bind(this, user)}>Edit</span>
                            </Row>
                        </NavDropdown>
                </Row>
            )
        })
    }

    render() {
        const {currentOrg, showEdit, editUser} = this.state;
        return(
            <div className="usersContainer pt-m">
                <span className="subtitle">Organization:</span>
                <Dropdown
                    className="mb-xl mt-s"
                    selection
                    fluid
                    options={this.getOrgOptions()}
                    onChange={(e, a) => this.handleOrgChange(e,a)}
                />
                { currentOrg !== null &&
                    <>
                        <CourtesyPoints/>
                        <EditUserAdminModal org_id={currentOrg} user={editUser} _show={showEdit} fetchUsers={this.fetchUsers} _handleClose={this.handleEditClose}/>
                        <EditUserAdminModal org_id={currentOrg} newUser={true} fetchUsers={this.fetchUsers}/>
                        <div className="usersTableContainer mt-xl">
                            <Row className="zeroMargin subtitle usersTableTitleRow">
                                <Col className="usersNameCol pl-z pr-s">
                                    User Name
                                </Col>
                                <Col className="usersEmailCol pl-z pr-s">
                                    Email
                                </Col>
                                <Col className="usersAdminCol pl-z pr-s">
                                    Admin
                                </Col>
                                <Col className="usersActiveCol pl-z pr-s">
                                    Active
                                </Col>
                                <Col className="usersActiveCol pl-z pr-s">
                                    Manager
                                </Col>
                                <Col className="usersMenuCol pl-z pr-s">
                                    {/* menu */}
                                </Col>
                            </Row>
                            <Scrollbars className="clientsScroll" >
                                {this.renderUsers()}
                            </Scrollbars>
                        </div>
                    </>
                }
                { currentOrg === null &&
                    <span className="ml-auto mr-auto mt-xxl title">Please select an org to see its users</span>
                }
            </div>
        )
    }
}

export default (Users);
