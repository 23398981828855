import React from 'react'
import { Form, Modal, Button } from 'react-bootstrap';
import {addSurvey} from '../../service/api';

import '../styles/OrderDetailModal.sass'

class NewSurveyModal extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            name:'',
            url: '',
            show: false
        }
    }

    handleClose = () => {
        this.setState({
            name: '',
            url: '',
            results_url: '',
            show: false
        })
        this.props.handleNew()
    }

    handleSave() {
        const {name, url, results_url} = this.state;
        addSurvey({name, url: url, organization_id: this.props.organization_id, results_url}).then(() => {
            this.handleClose()
        })
    }

    render() {
        return(
          <> 
            <span style={{color: "#3a71b5", fontWeight: 'bold', fontSize: 14, cursor: 'pointer'}} onClick={() => this.setState({show: true})}>
                Add New Survey
            </span>
            <Modal show={this.state.show} onHide={this.handleClose} centered={true}>
                <div style={{padding: 20}}>
                    <span style={{fontSize: 18, marginBottom: 10, fontWeight: 'bold'}}>
                        New Survey
                    </span>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        <Form.Control
                            style={{marginBottom: 10}}
                            placeholder='name'
                            onChange={(e) => this.setState({...this.state, name: e.target.value})}
                        />
                        <Form.Control
                            style={{marginBottom: 10}}
                            placeholder='typeform id'
                            onChange={(e) => this.setState({...this.state, url: e.target.value})}
                        />
                        <Form.Control
                            style={{marginBottom: 10}}
                            placeholder='Results URL'
                            onChange={(e) => this.setState({...this.state, results_url: e.target.value})}
                        />
                    </div>
                    <Button className="ml-auto" onClick={() => this.handleSave()}>Save</Button>
                </div>
            </Modal>
          </>
        )
    }
}

export default (NewSurveyModal)
