import React from 'react';
import NewGoalPeriod from '../../modals/NewGoalPeriod';
import { Scrollbars } from 'react-custom-scrollbars';
import { getGoalPeriods } from '../../../service/api';
import {Row, Col} from 'react-bootstrap';
import OptionSwitch from '../../OptionSwitch';

class PeriodTab extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            periods: []
        }
        this.handleRefresh = this.handleRefresh.bind(this)
    }

    async componentDidMount() {
        const periods = await getGoalPeriods()
        this.setState({periods})
    }

    async handleRefresh() {
        const periods = await getGoalPeriods()
        this.setState({periods})
    }

    renderPeriods() {
        let {periods} = this.state;
        periods = periods.sort((a,b) => (a.name > b.name) ? 1 : -1)
        return periods.map((period, index) => {
            return(
                <Row key={index} className="zeroMargin productsTableRow">
                    <OptionSwitch refresh={this.handleRefresh} type="period" data={period}/>
                </Row> 
            );
        })
    }

    render() {
        return(
            <div style={{marginRight: "auto", marginLeft: "auto", width: "75%"}}>
                <NewGoalPeriod/>
                <div className="categoriesTableContainer">
                    <Row className="zeroMargin productsTableTitleRow subtitle">
                        <Col className="categoriesIDCol pl-z pr-s">
                            ID
                        </Col>
                        <Col className="categoriesNameCol alignCenter pl-z pr-s">
                            Name
                        </Col>
                        <Col className="categoriesMenuCol pl-z pr-s">
                            {/* menu */}
                        </Col>
                    </Row>
                    <Scrollbars className="productsScroll" >
                            {this.renderPeriods()}
                    </Scrollbars>
                </div>
            </div>
        )
    }
}

export default (PeriodTab)
