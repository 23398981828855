import axios, {Method} from "axios";
import {getToken} from "../helpers/storage";
import {get} from 'lodash'
import {Publicity} from "../types";

interface MotivyBackendResponse<T>{
    success: boolean
    data: T
    error: any
    responseType?: any
}

interface Paginate<T> extends MotivyBackendResponse<T> {
    total: number,
    hasNext: boolean,
}


/**
 * Function that performs requests using axios and common configurations such as:
 * * Authorization headers
 * * API base url
 * @Param params Parameters
 * * method HTTP method to perform (GET, PUT, POST, PATCH, DELETE)
 * * endpoint Relative path of API endpoint to be fetched
 * * data Body of POST or PUT requests.
 * @returns Promise of requested API endpoint.
 * * Said response can be threatened as a rejected promise or a resolved promise
 * * For making React Query Works!
 */

async function apiService<T>(params: {
    method: Method,
    endpoint: string,
    options?:{
        data?: Record<string, any> | undefined,
        page?: number,
        perPage?: number,
    }
}): Promise< T | Paginate<T>> {

    const { options, endpoint, method } = params
    const data = get(options, "data", undefined)
    const page = get(options, "page", 0)
    const perPage = get(options, "perPage", 0)

    const url = `${process.env.REACT_APP_API_ENDPOINT}${endpoint}`
    const TOKEN = getToken()
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${TOKEN}`,
        Accept: 'application/json',
        'Access-Control-Allow-Origin': window.location.origin,
        'X-Motivy-Origin': window.location.origin
    }

    return new Promise((resolve, reject) => {
        axios<MotivyBackendResponse<T> | Paginate<T>>({ method, url, headers, data:{...data, perPage, page} })
            .then(res => {
                if (page > 0 ) {
                    resolve((res.data) as Paginate<T>)
                }else{
                    resolve((res.data.data) as T)
                }
            })
            .catch(err => reject(err))
    })
}

async function apiFormService<T>(params: {
    method: Method,
    endpoint: string,
    options:{
        data: FormData
    }
}): Promise< T > {

    const { options, endpoint, method } = params
    const data = get(options, "data")

    const url = `${process.env.REACT_APP_API_ENDPOINT}${endpoint}`
    const TOKEN = getToken()
    const headers = {
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${TOKEN}`,
        //Accept: 'multipart/form-data',
        'Access-Control-Allow-Origin': window.location.origin,
        'X-Motivy-Origin': window.location.origin
    }

    return new Promise((resolve, reject) => {
        axios<MotivyBackendResponse<T>>({ method, url, headers, data })
            .then(res => {
                resolve((res.data.data) as T)
            })
            .catch(err => reject(err))
    })
}

/* async function apiBlobService(params: {
    method: Method,
    endpoint: string,
    options:{
        data: Blob
    }
}): Promise< Blob > {

    const { options, endpoint, method } = params
    const data = get(options, "data")

    const url = `${process.env.REACT_APP_API_ENDPOINT}${endpoint}`
    const TOKEN = getToken()
    const headers = {
        "Content-Type": 'application/json',
        Authorization: `Bearer ${TOKEN}`,
        'Access-Control-Allow-Origin': window.location.origin,
    }
    const responseType =  'blob'
    return new Promise((resolve, reject) => {
        axios({ method, url, headers, data, responseType  })
            .then(res => {
                resolve(res.data)
            })
            .catch(err => reject(err))
    })
} */


// * * * * * * * * * * * * * * * *
// * * * PUBLICITY * * *
// * * * * * * * * * * * * * * * *


export async function postPublicity(data:any){

    return apiFormService<Publicity>({
        method: "POST",
        endpoint: "/publicity/create",
        options:{
            data
        }
    })
}


export async function updatePublicityImage(data:any){

    return apiFormService<Publicity>({
        method: "PUT",
        endpoint: "/publicity/update_image",
        options:{
            data
        }
    })
}


export async function updatePublicity(data:any){
    return apiService({
        method: "PUT",
        endpoint: '/publicity/update',
        options:{
            data,
            page: 0,
        }
    })
}


export async function publicity(){
    return apiService<Publicity[]>({
        method:  'GET',
        endpoint: '/publicity',
        options:{
            page:0,
        }
    })
}


export async function publicityOrg(id:any){
    return apiService<Publicity[]>({
        method:  'GET',
        endpoint: `/publicity/${id}`,
        options:{
            page:0,
        }
    })

}
