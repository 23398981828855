export const SET_ME = 'SET_ME';
export const SET_CATALOG = 'SET_CATALOG';
export const SET_CATEGORIES = 'SET_CATEGORIES';
export const SET_PRODUCTS = 'SET_PRODUCTS';
export const SET_ORDERS = 'SET_ORDERS';
export const SET_COUNTRIES = 'SET_COUNTRIES';
export const SET_TYC = 'SET_TYC';
export const SET_CITY = 'SET_CITY';


export const setCountries = (countries) => {
    return {type: SET_COUNTRIES,
    countries}
}

export const setOrders = (orders) => ({
    type: SET_ORDERS,
    orders
});

export const setProducts = (products) => ({
    type: SET_PRODUCTS,
    products
});

export const setCategories = (categories) => {
    return({type: SET_CATEGORIES,
    categories}
    )
};

export const setTyC = (termConditions) =>{
    return({type: SET_TYC,
    termConditions})
};

export const setMeAction = (user, initialized) => ({
    type: SET_ME,
    user,
    initialized,
});

export const setCatalogAction = (catalog) => ({
    type: SET_CATALOG,
    catalog
});
export const setCityAction = (city) => ({
    type: SET_CITY,
    city
});
