import { SET_COUNTRIES } from './actions';

const INITIAL_STATE = {
  countries: [],
};

export default function countriesReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_COUNTRIES:
      return { ...state, countries: action.countries };
    default:
      return state;
  }
}
