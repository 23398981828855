import React from 'react'
import { Tab } from 'semantic-ui-react';
import PeriodTab from './PeriodTab'
class Goals extends React.Component {



    renderGoalsMenu() {
        return [
            {
                menuItem: 'Periods',
                render: () => <Tab.Pane attached={false}><PeriodTab/></Tab.Pane>,
            },
        ]
    }

    render() {
        return (
            <>
                <div className="productsContainer">
                    <Tab className="productsTab" menu={{ secondary: true, pointing: true }} panes={this.renderGoalsMenu()} />
                </div>
            </>
        )
    }
}

export default (Goals);
