import React, {FC, useEffect, useState} from "react";
import {getOrgs, getOrgServices, getServices, updateOrgBalance, updateServices} from "../../../service/api";
import {Dropdown, Loader} from "semantic-ui-react";
import {Card, Button} from "react-bootstrap";
import {Alert, Paper, Stack, TextField, Typography} from "@mui/material";

type OrgServiceType = { org_id: number; service_id: number; service: ServiceType; active: boolean }
type ServiceType = { id: number; name: string; description: string; }

const Services:FC = () => {
    const [orgs, setOrgs] = useState([] as any [])
    const [currentOrg, setCurrentOrg] = useState<any>(null)
    const [pointsUpdated, setPointsUpdated] = useState(0)
    const [ services, setServices ] = useState<ServiceType[]>( [])
    const [ fullOrgService, setFullOrgService ] = useState<OrgServiceType[]>( [])
    const [loading,setLoading] = useState(false)
    const [success, setSuccess] = useState(false)
    const [visibleCount,setVisibleCount] = useState(false)
    const [orgBalance, setOrgBalance] = useState({
        current_balance: 0,
        points_to_distribute: 0,
    })
    const [details,setDetails] = useState("")
    useEffect( () => {
        const start = async () => {
            setOrgs( await getOrgs() )
            setServices( await fetchAvailableServices() );
        }
        start()
    }, [])

    const getOrgOptions = () => {
        return orgs.map((org, index) => {
            return {
                key: index,
                text: org.name,
                value: org.id
            }
        })
    }

    const handleOrgChange = (e: any, {value}: any) => {
        setOrgBalance({
            current_balance: orgs.find(org => org.id === value)?.balance.current_balance  ?? 0,
            points_to_distribute: orgs.find(org => org.id === value)?.balance.org_points_to_distribute ?? 0,
        })
        if (currentOrg === value) return;
        setCurrentOrg( value );
        fetchOrgServices( {orgID: value} ).then();
        setVisibleCount(false)
        setDetails("")
    }

    async function fetchAvailableServices( ) {
        return await getServices();
    }

    async function fetchOrgServices( { orgID }: { orgID: number }) {
        setFullOrgService( [] );
        const orgServices = await getOrgServices( orgID );
        const fullOrgService:OrgServiceType[] = (services || []).map( s => {
            const foundService = (orgServices || []).find( ( og:OrgServiceType ) => og.service_id === s.id );
            return foundService
                ? foundService
                : {org_id: orgID, service_id: s.id, service: s, active: false }
        })
        setFullOrgService( fullOrgService );
    }

    function handleServiceChange( { service }: { service: ServiceType } ) {
        const indexService = ( fullOrgService || [] ).findIndex( os => os.service_id === service.id );
        if ( indexService !== -1 ) {
            const updatedService = fullOrgService[indexService];
            fullOrgService[indexService].active = !updatedService.active;
            setFullOrgService([...fullOrgService]);
        } else {
            console.log( "service not found" );
        }
    }

    async function saveOrgServices( event: any ) {
        event.preventDefault();
        const formattedData = ( fullOrgService  ||  [] ).map(
            ( fos) => {
                return( { service_id: fos.service_id, active: fos.active } )
            }
        )
        const data = {
            services: formattedData,
            org_id: fullOrgService[0].org_id
        }
        await updateServices( data );
    }

    function handleSave() {
        setLoading( true)
        const data = {
            org_id: currentOrg,
            points: pointsUpdated,
            description: details
        }
        updateOrgBalance(data).then(res => {
            if (res) {
                setLoading(false)
                setSuccess(true)
                setTimeout(() =>setSuccess(false), 1000)
            }
            }
        ).catch(()=> console.log("Error saving points"))
    }

    function  handleChangePoints(e:any){
        setPointsUpdated(e.target.value!=='' ? parseInt(e.target.value) : 0)
        setVisibleCount(true)
    }

    function handleChangeDetails(e:any) {
        setDetails(e.target.value)
    }

    return (
        <div className="usersContainer pt-m">
            {loading && <Loader/>}
            {success && <Alert severity="success">Actualizacion existosa!</Alert>}
            <span className="subtitle">Organization:</span>
            <Dropdown
                className="mb-xl mt-s"
                selection
                fluid
                options={getOrgOptions()}
                onChange={(e, a) => handleOrgChange(e, a)}
            />
            {currentOrg !== null &&
                <>
                    <Paper elevation={2} sx={{ width: 800, height: 130, lineHeight: "5em", alignContent: "center",padding:2}}>
                        <Typography variant="h6"  color={"blueviolet"}>
                            Actulizar Balance
                        </Typography>
                        <Typography variant="subtitle1" >
                            Balance Corporativo Actual :
                            <span className={'font-weight-bold'}>{orgBalance?.current_balance}</span>
                            {
                                visibleCount && <span className={'font-weight-bold'}>
                                <span className={'font-weight-bold'}>---{">"}</span>
                                {orgBalance.current_balance + pointsUpdated }
                            </span>
                            }
                        </Typography>
                        <Typography variant="subtitle1" >
                            Puntos a distribuir :
                            <span className={'font-weight-bold'}>{orgBalance?.points_to_distribute}</span>
                            {
                                visibleCount && <span className={'font-weight-bold'}>
                                <span className={'font-weight-bold'}>---{">"}</span>
                                {orgBalance.points_to_distribute + pointsUpdated }
                            </span>
                            }
                        </Typography>
                        <Stack direction="row" style={{ zIndex: 2 }}>
                            <Typography variant="subtitle1" style={{ display: "flex", alignItems: "center",fontWeight:"bold"}}>
                                Sumar al balance:
                            </Typography>
                            <TextField  type="number"
                                    defaultValue={0}
                                    variant="standard"
                                    sx={{marginLeft:2}}
                                    onChange={handleChangePoints}
                            />
                            <TextField placeholder={"Detalles"}
                                       variant="standard"
                                       maxRows={2}
                                       value={details}
                                       multiline
                                       sx={{marginLeft:4,width: 300}}
                                       onChange={handleChangeDetails}
                            />
                            <Button variant={"success"}
                                    onClick={handleSave}
                                    style={{marginLeft:4}}
                            >Guardar</Button>
                        </Stack>
                        {/*visibleCount && <Typography variant="subtitle1"  color={"green"}>
                            Resultado: {orgBalance + pointsUpdated > 0 ? "+":""}{orgBalance + pointsUpdated }
                        </Typography>*/}
                    </Paper>

                    <div className={"services-container"}
                         style={{display: "flex", justifyContent: "center", alignItems: "center", flexWrap: "wrap"}}>
                        {
                            (fullOrgService || []).map((orgService) => {
                                return (
                                    <Card key={orgService.service_id} style={{width: '18rem', margin: 12}}>
                                        <Card.Body>
                                            <Card.Title>{orgService.service.name.toUpperCase()}</Card.Title>
                                            <Card.Text>{orgService.service.description}</Card.Text>
                                            {
                                                orgService.active
                                                    ? <Button variant="secondary"
                                                              onClick={() => handleServiceChange({service: orgService.service})}>Dissable</Button>
                                                    : <Button variant="success"
                                                              onClick={() => handleServiceChange({service: orgService.service})}>Activate</Button>
                                            }
                                        </Card.Body>
                                    </Card>
                                )
                            })
                        }
                    </div>
                    <div style={{display: "flex", justifyContent: "center", marginBottom: 12}}>
                        <Button style={{marginRight: 8}} size={"lg"} variant="secondary"
                                onClick={() => fetchOrgServices({orgID: currentOrg})}>Restore</Button>
                        <Button style={{marginLeft: 8}} size={"lg"} variant="primary"
                                onClick={(e) => saveOrgServices(e)}>Save</Button>
                    </div>

                </>
            }
            {currentOrg === null &&
                <span className="ml-auto mr-auto mt-xxl title">Please select an org to see its services</span>
            }
        </div>
    );
}

export default Services;