import React, {useState} from "react";
import {Button, Form, Modal, Spinner} from "react-bootstrap";
import {IconContext} from "react-icons";
import {IoMdClose} from "react-icons/io";
import {Radio} from "semantic-ui-react";
import {CustomAnalyticsInterface} from "./pages/Analytics/Analytics";

interface CustomAnalyticsEditSwitchProps {
    data: CustomAnalyticsInterface;
    handleAction: any;
    save: any;
    handlePublicState: any;
}

const CustomAnalyticsEditSwitch = ({ data, handleAction, save, handlePublicState }: CustomAnalyticsEditSwitchProps) => {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [loading, setLoading] = useState(false)
    const [analyticUrl, setAnalyticUrl] = useState(data.analytic_url || "")
    const [externalUrls, setExternalUrls] = useState(data.external_urls?.calibrate || "")
    const [edit, setEdit] = useState(false)


    const handleChangeActivateState = (): any => {
        setLoading( true );
        handleAction(data.id);
        setLoading( false );
    }

    const handleChangePublicState = (): any => {
        setLoading( true );
        handlePublicState(data.id);
        setLoading( false );
    }

    const handleSave = () => {
        save(analyticUrl, externalUrls);
        setEdit( false );
    }

    return (
        <div
            style={{ display: "flex", alignItems: "center" }}
            className="zeroMargin optionSwitchRow mb-m"
        >
            <Modal show={show} onHide={handleClose} style={{ height: 1000, width: 1000 }}>
                <Modal.Header closeButton>
                    <Modal.Title>Preview URL</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <iframe
                        width="800px"
                        height="800px"
                        title="dataStudio"
                        src={data.analytic_url}
                        frameBorder="0"
                        style={{border:0}}
                        allowFullScreen
                    />
                </Modal.Body>
            </Modal>
            <span className="vCenteredParent ml-m mr-auto">{data.title}</span>
            {!edit && ( <>
                {data.external_urls && (
                    // TODO: preview external URLS
                    <Button
                        href={data.external_urls?.calibrate}
                        target="_blank"
                        className="primaryLightButton mr-l"
                        style={{ marginLeft: 10}}
                    >
                        preview external URLS
                    </Button>
                )}
                <Button onClick={handleShow}>
                    Preview
                </Button>
            </>)}
            {edit && (<>
                    <Form.Control
                        style={{ width: "40%", marginLeft: 3 }}
                        type="text"
                        value={externalUrls}
                        onChange={(e) => setExternalUrls( e.target.value )}
                        placeholder="calibration button url"
                    />
                    <Form.Control
                        style={{ width: "40%", marginLeft: 3 }}
                        type="text"
                        value={analyticUrl}
                        onChange={(e) => setAnalyticUrl( e.target.value )}
                        placeholder="analytic url"
                    />
                </>
            )}
            {!edit && (
                <span
                    className="mr-xxl lightPrimary pointer"
                    onClick={() => setEdit( true )}
                >
                    Edit Url
                </span>
            )}
            {edit && (
                <>
                    <Button
                        className="primaryLightButton"
                        style={{ height: "80%", paddingTop: 2, marginLeft: 15 }}
                        onClick={handleSave}
                    >
                        Save
                    </Button>
                    <IconContext.Provider value={{ size: "20px" }}>
                        <span
                            style={{ marginRight: 3 }}
                            className="pointer ml-m title"
                            onClick={() => {
                                setEdit( false ); setAnalyticUrl( data.analytic_url )
                            }}
                        >
                            <IoMdClose />
                        </span>
                    </IconContext.Provider>
                </>
            )}
            {loading && (
                <Spinner className="mr-m" size="sm" animation="grow" variant="dark" />
            )}
            {!loading && !edit && (
                <Radio
                    className="mt-s mr-m"
                    toggle
                    checked={data.active}
                    onClick={handleChangeActivateState}
                />
            )}
            {!loading && !edit && (
                <Radio
                    className="mt-s mr-m"
                    toggle
                    checked={data.is_public}
                    onClick={handleChangePublicState}
                />
            )}
        </div>
    )
}

export default CustomAnalyticsEditSwitch;