import React, {useState} from "react";
import { Spinner, Form, Button } from "react-bootstrap";
import { Radio } from "semantic-ui-react";
import { IoMdClose } from "react-icons/io";
import { IconContext } from "react-icons";
import {PopupButton} from "@typeform/embed-react";
import {Survey} from "./pages/surveys/Surveys";


interface SurveyEditSwitchProps {
    data: Survey;
    handleAction: any;
    handleMultiResponse: any;
    save: any;
}

const SurveyEditSwitch = ({ data, handleAction, handleMultiResponse, save }: SurveyEditSwitchProps) => {
    const [loading, setLoading] = useState(false)
    const [url, setUrl] = useState(data.url || "")
    const [resultsUrl, setResultsUrl] = useState(data.results_url || "")
    const [edit, setEdit] = useState(false)


    const handleChangeActivateState = async (): Promise<any> => {
        setLoading( true );
        await handleAction(data.id);
        setLoading( false );
    }

    const handleChangeMultiResponseState = async (): Promise<any> => {
        setLoading( true );
        await handleMultiResponse(data.id);
        setLoading( false );
    }

    const handleSave = () => {
        save(url, resultsUrl);
        setEdit( false );
    }

    const getFromId = (url: string) => {
        const urlArr = url.split('/');
        return urlArr[urlArr.length - 1];
    }

    return (
        <div
            style={{ display: "flex", alignItems: "center" }}
            className="zeroMargin optionSwitchRow mb-m"
        >
            <span className="vCenteredParent ml-m mr-auto">{data.name}</span>
            {!edit && ( <>
                {data.results_url && (
                    <Button
                        href={data.results_url}
                        target="_blank"
                        className="primaryLightButton mr-l"
                        style={{ marginLeft: 10 }}
                    >
                        View results
                    </Button>
                )}
                <PopupButton id={getFromId(data.url)} style={{ fontSize: 20 }} className="lightPrimary">
                    Preview
                </PopupButton>
            </>)}
            {edit && (<>
                <Form.Control
                    style={{ width: "40%", marginLeft: 3 }}
                    type="text"
                    value={resultsUrl}
                    onChange={(e) => setResultsUrl( e.target.value )}
                    placeholder="Results url"
                />
                <Form.Control
                    style={{ width: "40%", marginLeft: 3 }}
                    type="text"
                    value={url}
                    onChange={(e) => setUrl( e.target.value )}
                    placeholder="curse url"
                />
            </>
            )}
            {!edit && (
                <span
                    className="mr-xxl lightPrimary pointer"
                    onClick={() => setEdit( true )}
                >
                    Edit Url
                </span>
            )}
            {edit && (
                <>
                    <Button
                        className="primaryLightButton"
                        style={{ height: "80%", paddingTop: 2, marginLeft: 15 }}
                        onClick={handleSave}
                    >
                        Save
                    </Button>
                    <IconContext.Provider value={{ size: "20px" }}>
                        <span
                            style={{ marginRight: 3 }}
                            className="pointer ml-m title"
                            onClick={() => {
                                setEdit( false ); setUrl( data.url )
                            }}
                        >
                            <IoMdClose />
                        </span>
                    </IconContext.Provider>
                </>
            )}
            {loading && (
                <Spinner className="mr-m" size="sm" animation="grow" variant="dark" />
            )}
            {!loading && !edit && (<>
                <Radio
                    className="mt-s mr-m"
                    toggle
                    checked={data.is_active}
                    onClick={handleChangeActivateState}
                />
                <Radio
                    className="mt-s mr-m"
                    toggle
                    checked={data.is_multi_response}
                    onClick={handleChangeMultiResponseState}
                />
            </>)}
        </div>
    );
}

export default SurveyEditSwitch;
