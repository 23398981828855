import {useMutation, UseMutationResult, useQuery, UseQueryResult} from "@tanstack/react-query"
import {Publicity} from "../../types";
import {postPublicity, updatePublicityImage, updatePublicity, publicity, publicityOrg} from "../../service/apiV3";


export const usePostPublicity = ( onSuccess?:any, onError?:any ):UseMutationResult<Publicity> => {
    return  useMutation(
        ['index', 'postPublicity'],
        (formData:any) => postPublicity(formData),
        {
            onSuccess: onSuccess,
            onError: onError,
        })

}


export const useUpdatePublicityImage = (onSuccess?:any, onError?:any):UseMutationResult<Publicity> => {
    return  useMutation(
        ['index', 'postPublicity'],
        (formData:any) => updatePublicityImage(formData),
        {
            onSuccess: onSuccess,
            onError: onError,
        })

}

export const useUpdatePublicity = (onSuccess?:any, onError?:any) => {
    return  useMutation(
        ['index', 'postPublicity'],
        updatePublicity,
        {
            onSuccess: onSuccess,
            onError: onError,
        })
}


export const useGetPublicity = ( onSuccess?:any, onError?:any ):UseQueryResult<Publicity[]> => {
    return useQuery(
        ['index', 'getPublicity'],
        publicity,
        {
            onSuccess: onSuccess,
            onError: onError,
        }
    )
}


export const useGetPublicityOrg = ( id:any ,onSuccess?:any, onError?:any ):UseQueryResult<Publicity[]> => {
    return useQuery(
        ['index', 'getPublicity'],
        ()=>publicityOrg(id),
        {
            onSuccess: onSuccess,
            onError: onError,
        }
    )
}
