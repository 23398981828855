import React, { Component } from 'react'
import { Button } from 'react-bootstrap'
import { upload_file } from '../helpers/upload.js'

class Upload extends Component {
    constructor(props) {
      super(props);
      this.state = {
        selectedFile: null
      }
    }

    onChangeHandler = event => {
        const { fileUploadHandler } = this.props;
        if ( fileUploadHandler ) {
          fileUploadHandler(event.target.files[0])
        }
        this.setState({
            selectedFile: event.target.files[0],
            loaded: 0,
        })
    }

    upload = async ()=> {
      await upload_file(this.props.type, this.state.selectedFile)
    }
  
    render() {
      return (
        <div>
          <input className="fullWidth" type="file" name="file" onChange={this.onChangeHandler}/>
          { this.props.upload &&
            <Button onClick={this.upload}> Upload </Button>
          }
        </div>
      )
    }
  }
  
export default (Upload)