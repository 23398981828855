import React from 'react';
import { Tab } from 'semantic-ui-react';
import {getCatalogs, getCategories, getAllProducts, getCountries, getAllTyC, getCity} from '../../../service/api';
import { connect } from 'react-redux';
import {setCatalogAction, setCategories, setProducts, setCountries, setTyC, setCityAction} from '../../../redux/actions';
import store from '../../../redux/store';
import ProductsTab from './ProductsTab';
import CatalogTab from './CatalogTab';
import CategoriesTab from './CategoriesTab';
import TyCTab from "./TyCTab";
import CityTab from './CityTab';
import '../../styles/Products.sass';
import {ManageForms} from "./Form/ManageForms";

class Products extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            activeItem: "Products",
        }
        this.renderProductsMenu = this.renderProductsMenu.bind(this)
        this.handleItemClick = this.handleItemClick.bind(this)
        this.fetchProducts = this.fetchProducts.bind(this)
    }

    handleItemClick (e, { name }) {
        this.setState({ activeItem: name})
    }

    async componentDidMount() {
        const catalog = await getCatalogs()
        const categories = await getCategories()
        const termConditions = await  getAllTyC()
        const city = await getCity() 
        
        store.dispatch(setCatalogAction(catalog));
        store.dispatch(setCategories(categories));
        store.dispatch(setTyC(termConditions));
        const countries = await getCountries()
        store.dispatch(setCountries(countries));
        store.dispatch(setCityAction(city));


        this.fetchProducts()
    }

    async fetchProducts() {
        let page = 1
        let res = await getAllProducts(page)
        store.dispatch(setProducts(res['data']));
        while (res['next']) {
            page += 1
            res = await getAllProducts(page)
            const {products} = this.props;
            var _products = products.slice().concat(res['data'])
            store.dispatch(setProducts(_products));
        }
    }

    renderProductsMenu() {
        return [
            {
                menuItem: 'Products',
                render: () => <Tab.Pane attached={false}><ProductsTab/></Tab.Pane>,
            },
            {
                menuItem: 'Catalog',
                render: () => <Tab.Pane attached={false}><CatalogTab/></Tab.Pane>,
            },
            {
                menuItem: 'Categories',
                render: () => <Tab.Pane attached={false}><CategoriesTab/></Tab.Pane>,
            },
            {
                menuItem: 'TyC',
                render: () => <Tab.Pane attached={false}><TyCTab/></Tab.Pane>,
            },
            {
                menuItem: 'City',
                render: () => <Tab.Pane attached={false}><CityTab/></Tab.Pane>,
            },
            {
                menuItem: 'Formulario',
                render: () => <Tab.Pane attached={false}><ManageForms/></Tab.Pane>,
            }

        ]
    }



    render () {

        return(
            <>
                <div className="productsContainer">
                    <Tab className="productsTab" menu={{ secondary: true, pointing: true }} panes={this.renderProductsMenu()} />
                </div>
            </>

        );
    }
}

const mapStateToProps = (state) => ({
    catalog: state.catalog.catalog,
    products: state.products.products,
    countries: state.countries.countries,
    termConditions: state.termConditions.termConditions,
    city: state.city.city
});

export default connect(mapStateToProps)(Products);
