import React from 'react';
import { connect } from 'react-redux';
import { Form, Button, Row } from 'react-bootstrap';
import '../styles/ForgotPassword.sass';
import { forgotPassoword } from '../../service/api'
import { withRouter } from 'react-router-dom';
import MotivyLogo from '../../assets/logoAndName.svg';
import {history} from '../Navigation';
import { Message } from 'semantic-ui-react';

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      errorMessage: null,
      showSuccess: false,
    };
  }

  componentWillReceiveProps(props) {
    // TODO:
    // If the user clicked a link to the login page but they are already logged in, redirect them to home
    // We are using JWT based authentication in the server side so we need to fetch key from cache and ping the server to see if it is valid

    let { me } = props;
    if (me != null) {
      history.push('/');
    }
  }

  async onSubmit(ev) {
    const { email } = this.state;
    ev.preventDefault();
    try {
      await forgotPassoword(email)
      this.setState({showSuccess: true})
      // Should we return user profile upon signin? I think so... change server
    } catch (error) {
      this.setState({ errorMessage: error.message });
    }
  }

  render() {
    const { email, showSuccess } = this.state;
    return (
      <div className="formContainer">
        {showSuccess &&
            <Message className="hCentered" positive header="Please check your email for a reset password link" />
        }
        {!showSuccess &&
            <>
                <div style={{ height: 35 }} />
                <img className="loginLogo" src={MotivyLogo} alt="React Logo" />
                <Row className="zeroMargin title mb-l">
                    Forgot your Password?
                </Row>
                <Row className="zeroMargin forgotPasswordDescription pl-xxl pr-xxl">
                    Enter your email address below and we’ll send you instructions on how to change your password.
                </Row>
                <Form className="form" onSubmit={(ev) => this.onSubmit(ev)}>
                <Form.Control type="text" placeholder="Email" value={email} onChange={(e) => this.setState({ email: e.target.value })} />
                <Button className="loginButton" variant="primary" type="submit">
                Send
                </Button>
                </Form>
            </>
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  me: state.me.me,
});

export default withRouter(connect(mapStateToProps)(ForgotPassword));
