import React from 'react';
import { Row, Col, Button, Modal, Form } from 'react-bootstrap';
import { Dropdown } from 'semantic-ui-react';
import SearchUsers from '../SearchUsers';
import { connect } from 'react-redux';
import { addAdminToOrg, removeAdminFromOrg, addOrg, editOrg } from '../../service/api';
import '../styles/ClientDetailsModal.sass';

class ClientDetailsModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            name: '',
            businessId: '',
            domain: '',
            email: '',
            country: '',
            address: '',
            contractStatus: '',
            catalogSelections: [],
            language: '',
            plan: 'basic',
            isLoading: false,
        }
        this.handleClose = this.handleClose.bind(this)
        this.handlePlanChange = this.handlePlanChange.bind(this)
        this.handleShow = this.handleShow.bind(this)
        this.getCatalogOptions = this.getCatalogOptions.bind(this)
        this.handleCatalogChange = this.handleCatalogChange.bind(this)
        this.handleSave = this.handleSave.bind(this)
        this.handleSearchResults = this.handleSearchResults.bind(this)
        this.getInitialCatalog = this.getInitialCatalog.bind(this)
        this.renderClientAdmins = this.renderClientAdmins.bind(this)
        this.handleLanguageChange = this.handleLanguageChange.bind(this)
    }

    attachmentType = 'product-image'

    handleShow = () => {
        this.setState({show: true})
    }

    handleClose = () => {
        this.setState({
            show: false,
            name: '',
            businessId: '',
            domain: '',
            email: '',
            country: '',
            address: '',
            contractStatus: '',
            catalogSelections: [],
            contractStartDate: '',
            contractEndDate: '',
            industry: '',
            language: '',
            isLoading: false
        })
    }

    handleSearchResults = async (user) => {
        const {data, onAddition} = this.props;
        await addAdminToOrg({userid: user.id, org_id: data.id})
        onAddition()
    }

    handleSave = async () => {
        const {edit, _handleClose, data} =  this.props
        const {name,
        businessId,
        domain,
        email,
        industry,
        country,
        address,
        catalogSelections,
        plan,
        language} = this.state
        if ((!name || !email || !country || !address || !language)) return 
        this.setState({isLoading: true})
        const {onAddition} = this.props;
        if (edit !== true) {
            this.setState({isLoading: true})
            await addOrg({
                name,
                business_id: businessId,
                sub_domain: domain,
                email,
                country,
                industry,
                address,
                catalogs: catalogSelections,
                language,
                plan: plan
            })
            onAddition()
            this.handleClose()
        }
        else {
            this.setState({isLoading: false})
            await editOrg({
                org_id: data.id,
                name,
                business_id: businessId,
                sub_domain: domain,
                email,
                country,
                industry,
                address,
                catalogs: catalogSelections,
                language
            })
            onAddition()
            _handleClose()
        }

    }

    getCatalogOptions() {
        const {catalog} = this.props;
        const options = catalog.map(cat => {
            return {name: cat.name, id: cat.id}
        })
        return options.map((value, index) => {
            return {
                key: index,
                text: value.name,
                value: value.id
            }
        })
    }

    getLanguageOptions() {
        return [
            {
                key: 0,
                text: 'Spanish',
                value: 'spanish'
            },
            {
                key: 1,
                text: 'English',
                value: 'english'
            }
        ]
    }

    getPlanOptions() {
        return [
            {
                key: 0,
                text: 'Basic',
                value: 'basic'
            },
            {
                key: 1,
                text: 'Premium',
                value: 'premium'
            }
        ]
    }

    componentWillReceiveProps(props) {
        const {data} = props
        if (data) {
            this.setState({
                name: data.name,
                businessId: data.business_id,
                domain: data.sub_domain,
                email: data.email,
                country: data.country,
                address: data.address,
                language: data.language,
                industry: data.industry,
                contractStatus: data.is_active,
                catalogSelections: data.catalogs ? data.catalogs.map(cat => cat.id) : [],
                isLoading: false,
                plan: data.subscription_type
            })
        }
    }

    handleRemoveAdmin = async (id) => {
        const { data, onAddition } = this.props;
        await removeAdminFromOrg({userid: id, org_id: data.id})
        onAddition()

    }

    handleCatalogChange(a, {value}) {
        this.setState({catalogSelections: value})
    }

    handleLanguageChange(e, {value}) {
        this.setState({language: value})
    }

    handlePlanChange(e, {value}) {
        this.setState({plan: value})
    }

    getInitialCatalog() {
        const {catalogSelections} = this.state
        const options = this.getCatalogOptions().filter(option => catalogSelections.find(selection => selection === option.key)).map(o => o.key)
        return options
    }

    renderClientAdmins() {
        const {data} = this.props;
        if (!data) return null
        const admins = data.admins || [];
        return admins.map((admin, index) => 
            <Row key={index} className="zeroMargin clientDetailModaladminRow">
                <span onClick={this.handleRemoveAdmin.bind(this, admin.id)} className="pointer mr-m">X</span><span>{`${admin.name} ${admin.lastname}`}</span>
            </Row>)
    }

    render() {
        const {show, address, name, businessId, domain, isLoading, email, country, industry} = this.state;
        const {edit, _show, _handleClose, data} = this.props;
        return(
            <>
            { edit !== true &&
                <span className="pointer" onClick={this.handleShow}>Add New Client</span>
            }
            <Modal show={edit === true ? _show : show} onHide={edit === true ? _handleClose : this.handleClose} centered={true}>
                <div className="modalContainer">
                    <Row className="zeroMargin">
                        <Col className="pl-z pr-m">
                            <Row className="zeroMargin subtitle">
                                Client Name:
                            </Row>
                            <Row className="zeroMargin mb-m">
                                <Form.Control type="text" placeholder="" value={name} onChange={(e) => this.setState({ name: e.target.value })} />
                            </Row>
                        </Col>
                        <Col className="pl-z pr-m">
                            <Row className="zeroMargin subtitle">
                                BusinessId:
                            </Row>
                            <Row className="zeroMargin mb-m">
                                <Form.Control type="text" placeholder="" value={businessId} onChange={(e) => this.setState({ businessId: e.target.value })} />
                            </Row>
                        </Col>
                    </Row>
                    <Row className="zeroMargin">
                        <Col className="pl-z pr-m">
                            <Row className="zeroMargin subtitle">
                            Link to Domain:
                            </Row>
                            <Row className="zeroMargin mb-m">
                                <Form.Control type="text" placeholder="" value={domain} onChange={(e) => this.setState({ domain: e.target.value })} />
                            </Row>
                        </Col>
                        <Col className="pl-z pr-m">
                            <Row className="zeroMargin subtitle">
                                Email:
                            </Row>
                            <Row className="zeroMargin mb-m">
                                <Form.Control type="text" placeholder="" value={email} onChange={(e) => this.setState({ email: e.target.value })} />
                            </Row>
                        </Col>
                    </Row>
                    <Row className="zeroMargin">
                        <Col className="pl-z pr-m">
                            <Row className="zeroMargin subtitle">
                                Country
                            </Row>
                            <Row className="zeroMargin mb-m">
                                <Form.Control type="text" placeholder="" value={country} onChange={(e) => this.setState({ country: e.target.value })} />
                            </Row>
                        </Col>
                        <Col className="pl-z pr-m">
                            <Row className="zeroMargin subtitle">
                                Address:
                            </Row>
                            <Row className="zeroMargin mb-m">
                                <Form.Control type="text" placeholder="" value={address} onChange={(e) => this.setState({ address: e.target.value })} />
                            </Row>
                        </Col>
                    </Row>
                    <Row className="zeroMargin mb-m">
                        <Col className="zeroPadding">
                            <Row className="zeroMargin subtitle">Language</Row>
                            <Dropdown
                                className="catalogDropdown"
                                selection
                                fluid
                                defaultValue={this.state.language}
                                options={this.getLanguageOptions()}
                                onChange={this.handleLanguageChange}
                            />
                        </Col>
                        <Col className="pl-m pr-z">
                            <span className="subtitle">Industry</span>
                            <Row className="zeroMargin">
                                <Form.Control type="text" placeholder="" value={industry} onChange={(e) => this.setState({ industry: e.target.value })} />
                            </Row>
                        </Col>
                        <Col className="pl-m pr-z ml-xxl">
                            <Row className="zeroMargin subtitle">Status</Row>
                            <Row className="zeroMargin">{data ? data.is_active ? 'Active' : 'Inactive' : ''}</Row>
                        </Col>
                    </Row>
                    <Row className="zeroMargin mb-m">
                        <Col className="zeroPadding">
                            <Row className="zeroMargin subtitle">Plan</Row>
                            <Dropdown
                                disabled={edit}
                                className="catalogDropdown"
                                selection
                                fluid
                                defaultValue={this.state.plan}
                                options={this.getPlanOptions()}
                                onChange={this.handlePlanChange}
                            />
                        </Col>
                        <Col className="pl-m pr-z">
                            <Row className="zeroMargin subtitle">Catalog</Row>
                            <Dropdown
                                className="catalogDropdown"
                                multiple
                                search
                                selection
                                fluid
                                defaultValue={this.getInitialCatalog()}
                                options={this.getCatalogOptions()}
                                onChange={this.handleCatalogChange}
                            />
                        </Col>
                    </Row>
                    { edit === true &&
                    <>
                        <Row className="zeroMargin mb-m">
                            HR Admin
                        </Row>
                        <Row className="zeroMargin">
                            <SearchUsers org={data ? data.id : -1} admin={false} disabled={false} userselect={this.handleSearchResults} />
                        </Row>
                        <div className="zeroMargin">
                            {this.renderClientAdmins()}
                        </div>
                    </>
                    }
                    <Row className="mt-l ml-z mr-xxl">
                        <Button disabled={isLoading} className="ml-auto mr-m" onClick={this.handleSave}>Save</Button>
                    </Row>
                </div>
            </Modal>
        </>
        );
    }
}

const mapStateToProps = (state) => {
    return({
        catalog: state.catalog.catalog,
    });
} 

export default connect(mapStateToProps)(ClientDetailsModal);
