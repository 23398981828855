import React from 'react'
import {Row, Col, NavDropdown} from 'react-bootstrap';
import { connect } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';
import { FaEllipsisV } from 'react-icons/fa';
import '../../styles/CityTab.sass';
import {getCity} from '../../../service/api';
import {setCityAction} from '../../../redux/actions';
import store from '../../../redux/store';
import NewCityModal from '../../modals/NewCityModal';
class CityTab extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            showEdit: false,
            editData: {}
        }
        this.renderCity = this.renderCity.bind(this)
    }

    renderCity() {
        const {city} = this.props;
        return city.map((city, index) => {
            return (
                <>
                <Row key={index} className="zeroMargin productsTableRow">
                    <Col className="cityIdCol pl-z pr-s">
                        {city.id}
                    </Col>
                    <Col className="cityNameCol pl-z pr-s">
                        {city.city_name}
                    </Col>
                    <Col className="cityCountCol alignCenter pl-z pr-s">
                        {city.products.length}
                    </Col>
                    <Col className="cityMenuCol pl-z pr-s">
                        <NavDropdown className="productsTableDropDown" drop="down" title={
                            <FaEllipsisV className="hCentered"/>
                        }>
                            <Row className="ml-l pointer mb-s">
                                <span onClick={this.handleEditClick.bind(this, city)}>Edit</span>
                            </Row>
                        </NavDropdown>
                    </Col>
                </Row>
                </>
            )
        })
    }

    handleEditClick(product) {
        this.setState({showEdit: true, editData: product})
    }

    handleEditClose() {
        this.setState({showEdit: false})
    }

    fetchCities = async () => {
        const {editData, showEdit} = this.state;
        const cities = await getCity()
        store.dispatch(setCityAction(cities));
        if (showEdit) {
            const city = cities.find(cit => cit.id === editData.id)
            if (city) this.setState({editData: city})
        }
    }

    render() {
        const {editData, showEdit} = this.state;
        return(
            <>
            <div className="catalogContainer">
                <NewCityModal edit={true} data={editData} _show={showEdit} _handleClose={this.handleEditClose.bind(this)} onAddition={this.fetchCities}/>
                <NewCityModal onAddition={this.fetchCities} />
                <div className="catalogTableContainer">
                    <Row className="zeroMargin productsTableTitleRow subtitle">
                        <Col className="cityNameCol pl-z pr-s">
                            Id
                        </Col>
                        <Col className="cityNameCol pl-z pr-s">
                            Name
                        </Col>
                        <Col className="catalogCountCol alignCenter pl-z pr-s">
                            # Products
                        </Col>
                        <Col className="cityCountCol pl-z pr-s">
                            {/* menu */}
                        </Col>
                    </Row>
                    <Scrollbars className="productsScroll" >
                            {this.renderCity()}
                    </Scrollbars>
                </div>
            </div>
            </>
        );
    }

}

const mapStateToProps = (state) => ({
    city: state.city.city
});

export default connect(mapStateToProps)(CityTab);
