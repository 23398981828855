import React, { useState, useEffect } from 'react';
import {
  TextField,
  FormControlLabel,
  Switch,
  Button,
  Select, Stack, Divider, Box, FormControl, InputLabel, MenuItem, Typography, Modal, Card, CardMedia, CardContent, Tooltip
} from '@mui/material';
/* import { LoadingButton } from '@mui/lab'; */
import { useForm, Controller } from 'react-hook-form';
import { getOrgs } from '../../../service/api'
import Upload from '../../Upload';
import { usePostPublicity, useUpdatePublicity, useUpdatePublicityImage } from '../../../hooks/Publicity/usePublicity'
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

interface FormData {
  titulo: string;
  org_id: string;
  img_url: any;
  descripcion: string;
  link: string;
  status: boolean;
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 900,
  height: 'auto',
  maxHeight: '100%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-evenly'
};

export default function FormCreatePublicity({ open, handleClose, handleUpdate, selectedPublicityItem, isEditMode = false, refetch, handleLoadinImgUpdate } :
   { open: boolean; handleClose: () => void; handleUpdate: (data:any) => void; selectedPublicityItem?: any | null;
    isEditMode?: boolean; refetch: ()=>void, handleLoadinImgUpdate: (load:any) => void | undefined }) {


  const { mutate: postPubli, isLoading: loadingPost } = usePostPublicity()
  const { mutate : updatePubli, isLoading: loadingUpdate } = useUpdatePublicity()
  const { mutate: updateImg, isLoading: loadUpdateImg } = useUpdatePublicityImage()

  const [ openSnack, setOpenSnack ] = React.useState(false);

  const handleClick = () => {
    setOpenSnack(true);
  };

  const handleCloseSnack = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnack(false);
  };


  const { handleSubmit, control, reset, setValue, watch } = useForm<FormData>({
    defaultValues: {
      titulo: '',
      org_id: '',
      img_url: '',
      descripcion: '',
      link: '',
      status: false
    }
  });

  const [previstaImg, setPrevistaImg] = useState('')
  const [file, setFile] = useState(null)


  const fileUploadHandler = (file:any) => {
    const preview = URL.createObjectURL(file)
    setFile(file)
    setPrevistaImg(preview)
  }


  const [selectedOrganization, setSelectedOrganization] = useState<any>(null);
  const [charCountTitle, setCharCountTitle] = useState(0);
  const [charCountDesc, setCharCountDesc] = useState(0);
  const [charCountLink, setCharCountLink] = useState(0);

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedOrganization(event.target.value as number);
  };

  const [orgs, setOrgs] = useState([] as any [])

  const filterOrgsEdit = orgs?.find((e:any) => e?.id === selectedPublicityItem?.org_id)
  const filterOrgsCreate = orgs?.find((e:any) => e?.id === selectedOrganization)


  const getAllOrgs = async () => {
    try {
      const data = await getOrgs()
      setOrgs(data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect( () => {
    getAllOrgs()
  }, [])

  useEffect(() => {
    handleLoadinImgUpdate(loadUpdateImg)
  }, [handleLoadinImgUpdate, loadUpdateImg])

  useEffect(() => {
    if (selectedPublicityItem) {
      // Si hay initialData, establece los valores iniciales de los campos del formulario
      setValue('titulo', selectedPublicityItem?.title);
      setValue('org_id', selectedPublicityItem?.org_id);
      setValue('descripcion', selectedPublicityItem?.description);
      setValue('img_url', selectedPublicityItem?.img_url);
      setValue('link', selectedPublicityItem?.link);
      setValue('status', selectedPublicityItem?.status);
    }

}, [selectedPublicityItem, setValue]);

const [ validateOrg, setValidateOrg ] = useState<boolean>(false)
const [ loading, setLoading ] = useState<boolean>(false) 


  const onSubmit = async (data: FormData) => {

    setLoading(true)

    if(selectedOrganization === null && !isEditMode) {
      setValidateOrg(true)
      setLoading(false)
      return
    }

    try {

      const formData = new FormData();
      formData.append('title', data.titulo);
      formData.append('org_id', selectedOrganization?.toString());
      formData.append('description', data.descripcion);
      formData.append('link', data.link);
      formData.append('status', data.status ? 'true' : 'false');

      if (file) formData.append('img_url', file)

      const _data = {
        id: selectedPublicityItem?.id,
        title: data.titulo,
        org_id: data.org_id,
        description: data.descripcion,
        link: data.link,
        status: data.status
      }

      if(file !== null && isEditMode) {

        const res = updateImg({
          id_publicity: selectedPublicityItem?.id,
          img_url: file
        }, {
          onSuccess: () => {
            handleUpdate(res);
            setValidateOrg(false)
            setLoading(false)
            setFile(null)
            setPrevistaImg('')
            reset()
            refetch()
          }
        })

      }

      if(!loadUpdateImg){
        const res = isEditMode && !loadUpdateImg
        ? updatePubli(_data, {
          onSuccess: () => {
            handleUpdate(res);
            setValidateOrg(false)
            setLoading(false)
            setFile(null)
            setPrevistaImg('')
            handleClick()
            reset()
            handleClose();
            refetch()
          }
        })
        : !loadUpdateImg && postPubli(formData, {
          onSuccess: () => {
            handleUpdate(res);
            setValidateOrg(false)
            setLoading(false)
            setFile(null)
            setPrevistaImg('')
            handleClick()
            reset()
            handleClose();
            refetch()
          }
        })
      }

      

    } catch (error) {
      console.error('Error al enviar el formulario:', error);
      throw error;
    }

    
  };

  const handleCloseModal = () => {
    reset();
    setLoading(false)
    setValidateOrg(false)
    setFile(null)
    setPrevistaImg('')
    handleClose();
  };

  const validateMaxCharacters = (value: string, type: string) => {
    if (value?.trim()?.length === 0 && type !== 'descripcion' && type !== 'link') {
    return 'El campo no puede estar vacío ni contener solo espacios en blanco';
    }
    if (value?.length > 250 && type === 'titulo') {
      return 'El título no puede tener más de 250 caracteres';
    }
    if (value?.length > 400 && type === 'img_url') {
      return 'La Url no puede tener más de 400 caracteres';
    }
    if (value?.length > 1200 && type === 'descripcion') {
      return 'La descripción no puede tener más de 1200 caracteres';
    }
    if (value?.length > 400 && type === 'link') {
      return 'El link no puede tener más de 400 caracteres';
    }
    return true;
  };

  return (

    <>

      <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleCloseSnack}>
        <Alert onClose={handleCloseSnack} severity="success" sx={{ width: '100%' }}>
          ¡Enviado con éxito!
        </Alert>
      </Snackbar>

      <Modal
        open={open}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
            height: '100%'
        }}
      >

          <Box sx={style}>

            
            
                <Stack sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    height: '100%',
                    width: '300px',
                    gap: 2
                }}>

                    <Upload upload={false} fileUploadHandler={fileUploadHandler}/>

                  <Controller
                    name="titulo"
                    control={control}
                    defaultValue={selectedPublicityItem?.title}
                    rules={{
                      required: 'El título es requerido',
                      validate: {
                        maxChars: (value) => validateMaxCharacters(value, "titulo"),
                      },
                    }}
                    render={({ field, fieldState }) => (
                      <div>
                        <TextField
                          {...field}
                          label="Título"
                          variant="outlined"
                          defaultValue={selectedPublicityItem?.title}
                          fullWidth
                          error={fieldState.invalid}
                          helperText={fieldState.error ? fieldState.error.message : null}
                          required
                          onChange={(e) => {
                            field.onChange(e);
                            setCharCountTitle(e.target.value.length);
                          }}
                        />
                        <p>{`${charCountTitle} / 250 caracteres`}</p>
                      </div>
                    )}
                  />

                    <Controller
                        name="descripcion"
                        control={control}
                        defaultValue={selectedPublicityItem?.description}
                        rules={{
                          validate: {
                            maxChars: (value) => validateMaxCharacters(value, "descripcion"),
                          },
                        }}
                        render={({ field, fieldState }) => (
                        <div>
                        <TextField
                            {...field}
                            label="Descripción"
                            variant="outlined"
                            fullWidth
                            rows={4}
                            multiline
                            defaultValue={selectedPublicityItem?.description}
                            error={fieldState.invalid}
                            helperText={fieldState.error ? fieldState.error.message : null}
                            onChange={(e) => {
                              field.onChange(e);
                              setCharCountDesc(e.target.value.length);
                            }}
                        />
                        <p>{`${charCountDesc} / 1200 caracteres`}</p>
                        </div>
                        )}
                    />

                    <Controller
                        name="link"
                        control={control}
                        defaultValue={selectedPublicityItem?.link}
                        rules={{
                          validate: {
                            maxChars: (value) => validateMaxCharacters(value, "link"),
                          },
                        }}
                        render={({ field, fieldState }) => (
                          <div>
                        <TextField
                            {...field}
                            label="Enlace"
                            variant="outlined"
                            defaultValue={selectedPublicityItem?.link}
                            fullWidth
                            error={fieldState.invalid}
                            helperText={fieldState.error ? fieldState.error.message : null}
                            onChange={(e) => {
                              field.onChange(e);
                              setCharCountLink(e.target.value.length);
                            }}
                        />
                        <p>{`${charCountLink} / 400 caracteres`}</p>
                        </div>
                        )}
                    />

                      <FormControl fullWidth required>
                    
                        <InputLabel id="demo-simple-select-label">Organización*</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={selectedOrganization}
                          onChange={(e:any)=>handleChange(e)}
                          label="Organización"
                          required
                        >
                          {orgs?.map((organization) => (
                            <MenuItem key={organization.id} value={organization.id}>
                              {organization.name}
                            </MenuItem>
                          ))}
                        </Select>
                        <p>Organización seleccionada: {selectedOrganization === null ? filterOrgsEdit?.name : filterOrgsCreate?.name}</p>
                    </FormControl>
                    
                    { validateOrg && (
                      <Typography sx={{color:'red'}}>*Seleccione una organización</Typography>
                    )}

                    <Controller
                        name="status"
                        control={control}
                        defaultValue={false}
                        render={({ field }) => (
                        <FormControlLabel
                            label={`Status: ${watch('status') === true ? 'Activo' : 'Inactivo'}`}
                            control={<Switch {...field} defaultChecked={selectedPublicityItem?.status} />}
                        />
                        )}
                    />

                    <Stack sx={{display:'flex', flexDirection: 'row', gap: 2}}>

                      <Box sx={{ m: 1, position: 'relative', display: 'flex', flexDirection: 'row', gap:2 }}>

                        <Button disabled={ loading } variant="contained" color="primary" onClick={handleSubmit(onSubmit)}>
                          Enviar
                        </Button>
                        <Button onClick={handleCloseModal} variant="contained" color='error' >
                          Cerrar
                        </Button>

                        { loadingPost || loadingUpdate ? (
                          <CircularProgress
                            size={24}
                            sx={{
                              position: 'absolute',
                              top: '50%',
                              left: '50%',
                              marginTop: '-12px',
                              marginLeft: '-12px',
                            }}
                          />
                        ): ""}
                      </Box>

                      
                    </Stack>

                </Stack>
      

              <Divider orientation="vertical" />

              <Box sx={{
                    display:'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    maxHeight: '100%',
                    gap:2
                }}>
                    <Typography variant='h4'>Preview:</Typography>

                      <Card sx={{ 
                        width: 400,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                      }}>

                          <CardMedia
                            title='img_publicity'
                            sx={{ height: 140, backgroundSize: 'contain' }}
                            image={ isEditMode && file === null ? selectedPublicityItem?.img_url : previstaImg }
                          />
                          
                          <CardContent>
                            <Tooltip title={watch('titulo')?.length > 30 && watch('titulo')} disableHoverListener={ watch('titulo')?.length <= 30 }>
                              <Typography variant='h4' sx={{ wordBreak: "break-word", textAlign: "center"}}>
                              {watch('titulo')?.length > 30 ? watch('titulo')?.slice(0, 30) + "..." : watch('titulo')}
                              </Typography>
                            </Tooltip>
                            <Tooltip title={watch('descripcion')?.length > 30 && watch('descripcion')} disableHoverListener={ watch('descripcion')?.length <= 30 }>
                              <Typography variant='h6' sx={{ wordBreak: "break-word", textAlign: "center" }}>
                                  {watch('descripcion')?.length > 30 ? watch('descripcion')?.slice(0, 30) + "..." : watch('descripcion')}
                              </Typography>
                            </Tooltip>
                          </CardContent>
                    </Card>
                </Box>

          </Box>
        </Modal>

        </>
  );
}