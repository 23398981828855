import React from 'react';
import { Row, Button, Modal, Form } from 'react-bootstrap';
import { addGoalPeriod } from '../../service/api';
import '../styles/NewGoalPeriod.sass';

class NewGoalPeriod extends React.Component {
    constructor(props){
        super(props);
            this.state = {
                show: false,
                name: ''
            }
            this.handleShow = this.handleShow.bind(this)
            this.handleClose = this.handleClose.bind(this)
    }

    handleShow = () => {
        this.setState({show: true})
    }

    componentWillReceiveProps(props) {
        const {category} = props;
        if (category) this.setState({name: category.name})
    }
    handleClose = () => {
        this.setState({show: false})
    }

    handleSave = async () => {
        const {name} = this.state;
        const {edit, _handleClose} = this.props;
        await addGoalPeriod({name});
        if (edit) _handleClose()
        else this.handleClose()

    }

    render() {
        const {show, name} = this.state
        const {edit, _show, _handleClose} = this.props;
        return(
            <>  { edit !== true &&
                <span className="ml-auto mb-l newCategoryModalShow pointer" onClick={this.handleShow}>Create New Period</span>
            }
            <Modal show={edit === true ? _show : show} onHide={edit === true ? _handleClose : this.handleClose} centered={true}>
                <div className="newCategoryModalContainer">
                    <Row className="zeroMargin mb-m subtitle">
                        Period Name:
                    </Row>
                    <Row className="zeroMargin mb-xxl">
                        <Form.Control style={{maxWidth: 365}} type="text" placeholder="" value={name} onChange={(e) => this.setState({ name: e.target.value })} />
                        <Button onClick={this.handleSave} className="saveButton ml-auto">Save</Button>
                    </Row>
                </div>
            </Modal>
            </>
        );
    }
}

export default (NewGoalPeriod);
