import { createStore, combineReducers } from 'redux';
import meReducer from './meReducer';
import catalogReducer from './catalogReducer';
import categoriesReducer from './categoriesReducer';
import productsReducer from './productsReducer';
import ordersReducer from './ordersReducer';
import countriesReducer from './countriesReducer';
import tycReducer from './tycReducer';
import cityReducer from './cityReducer';

const rootReducer = combineReducers({
    me: meReducer,
    catalog: catalogReducer,
    categories: categoriesReducer,
    products: productsReducer,
    orders: ordersReducer,
    countries: countriesReducer,
    termConditions: tycReducer,
    city: cityReducer
})

export default createStore(rootReducer);
