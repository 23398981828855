import React from 'react'
import {Row, Col, NavDropdown} from 'react-bootstrap';
import { FaEllipsisV } from 'react-icons/fa';
import { Scrollbars } from 'react-custom-scrollbars';
import ClientDetailsModal from '../../modals/ClientDetailsModal';
import ChangeClientPlanModal from '../../modals/ChangeClientPlanModal';
import {getCatalogs, getOrgs} from '../../../service/api';
import {setCatalogAction} from '../../../redux/actions';
import store from '../../../redux/store';

import '../../styles/Clients.sass'

class Clients extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            orgs: [],
            editData: {},
            showEdit: false,
            showPlanEdit: false,
            editPlanData: null
        }
        this.renderClients = this.renderClients.bind(this)
        this.handleEditClick = this.handleEditClick.bind(this)
        this.handleEditClose = this.handleEditClose.bind(this)
        this.fetchOrgs = this.fetchOrgs.bind(this)
        this.handlePlanEditClose = this.handlePlanEditClose.bind(this)
        this.handleModalUpdate = this.handleModalUpdate.bind(this)
    }

    componentDidMount = async () => {
        this.fetchOrgs()
        const catalog = await getCatalogs()
        store.dispatch(setCatalogAction(catalog));
    }

    fetchOrgs  = async () => {
        const orgs = await getOrgs()
        this.setState({orgs})
    }

    async handleModalUpdate() {
        await this.fetchOrgs()
        const {orgs, showEdit, editData} = this.state
        if (showEdit){
            const displayedOrg = orgs.find(org => org.id === editData.id)
            this.setState({editData: displayedOrg})
        }
    }

    handleEditClick = (org) => {
        this.setState({editData: org, showEdit: true})
    }

    handlePlanClick = (orgid) => {
        this.setState({editPlanData: orgid, showPlanEdit: true})
    }

    handlePlanEditClose() {
        this.setState({editPlanData: null, showPlanEdit: false})
    }

    handleEditClose() {
        this.setState({showEdit: false})
    }

    renderClients = () => {
        const {orgs} = this.state;
        return orgs.map((org, index) => {
            return (
                <Row key={index} className="zeroMargin clientsTableRow">
                    <Col className="clientsNameCol pl-z pr-s">
                        {org.name}
                    </Col>
                    <Col className="clientsUserCountCol pl-z pr-s">
                        {org.users.length}
                    </Col>
                    <Col className="clientsCatalogsCol pl-z pr-s">
                        {org.catalogs.map(cat => cat.name).join(", ")}
                    </Col>
                    <Col className="clientsAdminsCol pl-z pr-s">
                        {org.admins.map(admin => `${admin.name} ${admin.lastname}`).join(", ")}
                    </Col>
                    <Col className="clientsInvoicesCol pl-z pr-s">
                        {org.open_invoices}
                    </Col>
                    <Col className="clientsContractStatusCol pl-z pr-s">
                        {org.is_active ? 'Active' : 'Inactive'}
                    </Col>
                    <Col className="clientsDomainCol pl-z pr-s">
                        {org.sub_domain}
                    </Col>
                    <Col className="clientsMenuCol pl-z pr-s">
                        <NavDropdown className="productsTableDropDown" drop="down" title={
                            <FaEllipsisV className="hCentered"/>
                        }>
                            <Row className="ml-l pointer mb-s">
                                <span onClick={this.handleEditClick.bind(this, org)}>Edit</span>
                            </Row>
                            <Row className="ml-l pointer mb-s">
                                <span onClick={this.handlePlanClick.bind(this, org)}>Change Plan</span>
                            </Row>
                        </NavDropdown>
                    </Col>
                </Row>
            )
        })
    }

    render() {
        const {editData, showEdit, showPlanEdit, editPlanData} = this.state
        return(
            <div className="clientsContainer">
                <ClientDetailsModal edit={true} data={editData} _show={showEdit} _handleClose={this.handleEditClose.bind(this)} onAddition={this.handleModalUpdate} />
                <ChangeClientPlanModal show={showPlanEdit} org={editPlanData} handleClose={this.handlePlanEditClose}/>
                <Row className="zeroMargin mt-xl">
                    <ClientDetailsModal />
                </Row>
                <div className="clientsTableContainer mt-xl">
                    <Row className="zeroMargin subtitle clientsTableTitleRow">
                        <Col className="clientsNameCol pl-z pr-s">
                            Client Name
                        </Col>
                        <Col className="clientsUserCountCol pl-z pr-s">
                            # Users
                        </Col>
                        <Col className="clientsCatalogsCol pl-z pr-s">
                            Catalogs
                        </Col>
                        <Col className="clientsAdminsCol pl-z pr-s">
                            HR Admins
                        </Col>
                        <Col className="clientsInvoicesCol pl-z pr-s">
                            Open Invoices
                        </Col>
                        <Col className="clientsContractStatusCol pl-z pr-s">
                            Status
                        </Col>
                        <Col className="clientsDomainCol pl-z pr-s">
                            Domain
                        </Col>
                        <Col className="clientsMenuCol pl-z pr-s">
                        </Col>
                    </Row>
                    <Scrollbars className="clientsScroll" >
                        {this.renderClients()}
                    </Scrollbars>
                </div>
            </div>
        )
    }
}

export default (Clients)
