import React from "react";
import {FC} from "react";
import {getOrders} from "../../../service/api";
import {Box, Tab, Tabs, Typography} from "@mui/material";
import {RenderOrders} from "./RenderOrders";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}


export const Orders: FC = () => {
    const [value, setValue] = React.useState(1);

    const fetchOrders = async (page : number,items:number,status:string,keyword:string, product_id:number) => {
        return  await getOrders(page,items,status,keyword, product_id);
    }

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Tabs
                value={value}
                onChange={handleChange}
                aria-label="wrapped label tabs example"
            >
                <Tab value={1} label="Open Orders" />
                <Tab value={2} label="Closed Orders" />
            </Tabs>
            <CustomTabPanel value={value} index={1}>
                <RenderOrders
                    fetchFunc={fetchOrders}
                    type={'open'} />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
                <RenderOrders
                    fetchFunc={fetchOrders}
                    type={'closed'} />
            </CustomTabPanel>
        </Box>
    );
}

export default Orders;