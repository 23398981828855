import React, {FC, useCallback, useEffect, useState} from "react"
import {Dialog, DialogContent, DialogTitle, Divider, Grid, IconButton, Stack, Typography} from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';
import {getFormResponses} from "../../../service/api";
import RenderAnswers from "./RenderAnswers";

interface OrderInfo {
    product_name?: string;
    quantity?: number;
    fullProduct: {
        id: number;
        price: number;
        is_corporate_benefit?: string;
        description?: string;
        is_demon_price: boolean;
        prices: any[];
    };
    id?: number;
    user_name?: string;
    user_email?: string;
    fullUser: {
        id: number;
        location?: string;
        phone?: string;
        address?: string;
        dni?: string;
        organization?: {
            name?: string;
        };
    };
}

interface ShowOrderInfoProps {
    data: OrderInfo;
}

const OrderInfoItem: FC<{ label: string; value: any }> = ({ label, value }) => (
    <Stack direction="row" spacing={1}>
        <Typography fontWeight="bold">{label}: </Typography>
        <span dangerouslySetInnerHTML={{__html: value}} />
    </Stack>
);
export const ShowOrderInfo: FC<ShowOrderInfoProps> = ({ data }) => {
    const [open, setOpen] = useState(false);
    const [responses, setResponses] = useState<any>([])

    const getResponses = useCallback(
        async () => {
            const data_:any = {
                product_id:data?.fullProduct?.id,
                user_id:data?.fullUser?.id
            }
            const res = await getFormResponses(data_)
            setResponses(res)

    },[data])

    useEffect(() => {
        getResponses()
    },[getResponses])

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <IconButton onClick={handleOpen}>
                <VisibilityIcon />
            </IconButton>
            <Dialog open={open} onClose={handleClose} maxWidth={false}>
                <DialogTitle>Información completa</DialogTitle>
                <DialogContent sx={{ width: 800 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <OrderInfoItem label="Product" value={data.product_name || ''} />
                            <OrderInfoItem label="Amount" value={data.quantity?.toString() || ''} />
                            <OrderInfoItem
                                label="Product Price"
                                value={data.fullProduct?.is_demon_price ? data.fullProduct?.price?.toString()
                                    : data.fullProduct?.price?.toString()
                                    || ''}
                            />
                            <OrderInfoItem label="Is Demon Price" value={!!data.fullProduct?.is_demon_price} />
                            <OrderInfoItem label="SKU" value={data.id} />
                            <OrderInfoItem
                                label="Is Corporate"
                                value={data.fullProduct?.is_corporate_benefit || 'Unknown'}
                            />
                            <OrderInfoItem label="Vendor Name" value={data.product_name || 'Unknown'} />
                            <OrderInfoItem label="Product description" value={data.fullProduct?.description || 'Unknown'} />

                        </Grid>
                        <Grid item xs={6}>
                            <OrderInfoItem label="User Name" value={data.user_name || ''} />
                            <OrderInfoItem label="User Email" value={data.user_email || 'Unknown'} />
                            <OrderInfoItem label="Location" value={data.fullUser?.location || 'Unknown'} />
                            <OrderInfoItem label="Phone" value={data.fullUser?.phone || 'Unknown'} />
                            <OrderInfoItem label="Address" value={data.fullUser?.address || 'Unknown'} />
                            <OrderInfoItem label="DNI" value={data.fullUser?.dni || 'Unknown'} />
                            <OrderInfoItem
                                label="Organization"
                                value={data.fullUser?.organization?.name || 'Unknown'}
                            />
                        </Grid>
                    </Grid>
                    <Divider sx={{m:2}} orientation={'horizontal'}/>
                    <RenderAnswers data={responses}/>
                </DialogContent>
            </Dialog>
        </>
    );
};