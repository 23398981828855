import React from 'react'
import {
    Box
} from '@mui/material'
import PublicityLayout from './PublicityLayout'

const Publicity = () => {
    
    return (
        <Box>
            <PublicityLayout />
        </Box>
    )
    
}

export default Publicity;