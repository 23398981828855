import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Dropdown } from 'semantic-ui-react';
import {updatePlan} from '../../service/api';

class ChangeClientPlanModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            plan: '',
            isLoading: false
        }
        this.handlePlanChange = this.handlePlanChange.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    handlePlanChange(e, {value}) {
        this.setState({plan: value})
    }

    getPlanOptions() {
        return [
            {
                key: 0,
                text: 'Basic',
                value: 'basic'
            },
            {
                key: 1,
                text: 'Premium',
                value: 'premium'
            }
        ]
    }

    async handleSave() {
        const {org} = this.props;
        const {plan} = this.state;
        this.setState({isLoading: true})
        await updatePlan({plan}, org.id)
        this.setState({isLoading: false})
    }

    componentWillReceiveProps(props) {
        const {org} = props;
        if(org) {
            this.setState({plan: org.subscription_type})
        }
    }

    handleClose() {
        this.setState({plan: ''})
        this.props.handleClose()
    }

    render() {
        const {show} = this.props;
        const {isLoading} = this.state;
        return(
            <Modal show={show} onHide={this.handleClose} centered={true}>
                <div className="modalContainer">
                    Change Org Plan
                    <Dropdown
                        className="catalogDropdown"
                        selection
                        fluid
                        defaultValue={this.state.plan}
                        options={this.getPlanOptions()}
                        onChange={this.handlePlanChange}
                    />
                    <Button disabled={isLoading} className="ml-auto mr-m" onClick={this.handleSave}>Save</Button>
                </div>
            </Modal>
        );
    }

}

export default ChangeClientPlanModal;
