import React, { useState } from 'react'
import PublicityTab from './PublicityTab'
import {
    Box,
    Button,
    Stack
} from '@mui/material'
import FormCreatePublicity from './FormCreatePublicity'
import { getPublicity } from '../../../service/api';
import { useQuery } from '@tanstack/react-query'

export default function PublicityLayout () {

    const [open, setOpen] = useState(false);
    const [update, setUpdate] = useState<any>({})

    const { data: publicity, refetch } = useQuery(['get-publicity'], {
        queryFn: getPublicity,
        refetchOnWindowFocus: false
      })

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleUpdate = (data:any) => {
        setUpdate(data)
    }

    const x = () => {}

    return (
        <Box sx={{
            padding: 15,
            gap: 5,
            display: 'flex',
            flexDirection: 'column'
        }}>
            <Stack>
                <Button variant="contained" color="primary" onClick={handleOpen} sx={{width:'20%'}}>
                    Crear publicidad
                </Button>
            </Stack>
            <FormCreatePublicity open={open} handleClose={handleClose} handleUpdate={handleUpdate} refetch={refetch} handleLoadinImgUpdate={x} />
            <PublicityTab update={update} allData={ publicity } />
        </Box>
    )
}