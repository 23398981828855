import React from "react";
import MotivyLogo from "../../assets/logoAndName.svg";
import { Form, Button, Alert, Row } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { activateStaffAccount } from "../../service/api";
import { history } from "../Navigation";


import "../styles/Activate.sass";

class Activate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      _password: "",
      errorMessage: null,
      checked: false,
    };
  }

  UNSAFE_componentWillReceiveProps(props) {
    let { me } = props;
    if (me.is_initiated) {
      history.push("/");
    }
  }

  async onSubmit(ev) {
    const { _password, password } = this.state;
    ev.preventDefault();
    if (_password === "" || password === "")
      return this.setState({
        errorMessage: "Please complete all fields below to continue",
      });
    if (_password !== password)
      return this.setState({
        errorMessage: "The provided passwords do not match!",
      });
    try {
      await activateStaffAccount({ password });
      history.push("/");
    } catch (error) {
      this.setState({ errorMessage: error.message });
    }
  }

  render() {
    const { password, _password, errorMessage, checked } = this.state;
    return (
      <div className="formContainer">
        <div style={{ height: 35 }} />
        <img className="loginLogo" src={MotivyLogo} alt="React Logo" />
        <span className="subtitle mt-m alignCenter">
          <span className="title">Thank You For Joining</span>{" "}
          <br />
          Active and initiate new staff account changing the password
        </span>
        <Form className="form" onSubmit={(ev) => this.onSubmit(ev)}>
          <div
            style={errorMessage == null ? { visibility: "hidden" } : undefined}
          >
            <Alert variant="danger">{errorMessage}</Alert>
          </div>
          <Form.Control
            type="password"
            placeholder="New Password"
            value={_password}
            onChange={(e) => this.setState({ _password: e.target.value })}
          />
          <Form.Control
            type="password"
            placeholder="Confirm New Password"
            value={password}
            onChange={(e) => this.setState({ password: e.target.value })}
          />
          <Row className="zeroMargin">
            <Form.Check
              checked={checked}
              type={"radio"}
              onClick={() => this.setState({ checked: !checked })}
            />
            <span className="mr-s">Accept Terms</span>
          </Row>
          <Button
            disabled={!checked}
            className="loginButton"
            variant="primary"
            type="submit"
          >
            Change Password
          </Button>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  me: state.me.me,
});

export default withRouter(connect(mapStateToProps)(Activate));
