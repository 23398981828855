import { useState, useEffect } from 'react';
import {getForms} from "../../../../../service/api";

export const useGetForms = () => {

    const [forms, setForms] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getForms().then((res) => {
            setForms(res);
        }).catch((err) => {
            console.log(err);
        })
        setLoading(false);
    },[])

    return { forms, loading };

}
