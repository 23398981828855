// Local storage helper. It will use used to store auth tokens for now

// TODO: find a better key for token to avoid packages being able to guess token location

// setter
export const setToken = (token) => {
    localStorage.setItem('auth_token', token);
}
// getter
export const getToken = () => {
    const token = localStorage.getItem('auth_token') || ''
    if (token === '' || token === 'undefined') return null
    // const token_payload = token.split('.')[1];
    // const token_claims = JSON.parse(window.atob(token_payload));
    // TODO: check expiry date
    return token 
}
// remove
export const removeToken = () => {
    localStorage.removeItem('auth_token');
}

// TODO: Remove all