import React from "react";
import {Button, Form, Modal} from "react-bootstrap";
import {addCustomAnalytic} from "../../service/api";

class NewCustomAnalytic extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            title:'',
            analytic_url: '',
            external_urls: '',
            show: false
        }
    }

    handleClose = () => {
        this.setState({
            title: '',
            analytic_url: '',
            external_urls: '',
            show: false
        })
        this.props.handleNew()
    }

    handleSave() {
        const {title, analytic_url, external_urls} = this.state;

        const external =  external_urls ? { "calibrate": external_urls } : null
        addCustomAnalytic( this.props.organization_id, {title, analytic_url, external_urls: external})
            .then(() => { this.handleClose() } );
    }

    render() {
        return(
            <>
            <span style={{color: "#3a71b5", fontWeight: 'bold', fontSize: 14, cursor: 'pointer'}} onClick={() => this.setState({show: true})}>
                Add Custom analytic
            </span>
                <Modal show={this.state.show} onHide={this.handleClose} centered={true}>
                    <div style={{padding: 20}}>
                    <span style={{fontSize: 18, marginBottom: 10, fontWeight: 'bold'}}>
                        New Custom analytic
                    </span>
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                            <Form.Control
                                style={{marginBottom: 10}}
                                placeholder='title'
                                onChange={(e) => this.setState({...this.state, title: e.target.value})}
                            />
                            <Form.Control
                                style={{marginBottom: 10}}
                                placeholder='analytic url'
                                onChange={(e) => this.setState({...this.state, analytic_url: e.target.value})}
                            />
                            <Form.Control
                                style={{marginBottom: 10}}
                                placeholder='External URLS'
                                onChange={(e) => this.setState({...this.state, external_urls: e.target.value})}
                            />
                        </div>
                        <Button className="ml-auto" onClick={() => this.handleSave()}>Save</Button>
                    </div>
                </Modal>
            </>
        )
    }
}

export default (NewCustomAnalytic)
