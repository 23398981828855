import { SET_TYC } from './actions';

const INITIAL_STATE={
    termConditions: [],
};

export default function termConditions(state = INITIAL_STATE, action){
    switch (action.type){
        case SET_TYC:
            return{...state, termConditions: action.termConditions};
        default:
            return state
    }
}