import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Tooltip, IconButton, Select, MenuItem, InputLabel, FormControl, TableFooter, TablePagination } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { getOrgs, getPublicity } from '../../../service/api'
import FormCreatePublicity from './FormCreatePublicity';
import { useQuery } from '@tanstack/react-query'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import BlockIcon from '@mui/icons-material/Block';

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number,
  ) => void;
}

function TablePaginationActions(props: TablePaginationActionsProps) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}


export default function PublicityTab(props:any) {


    const { data: orgOptions, isLoading: loadOrgs } = useQuery(['get-orgs'], {
      queryFn: getOrgs,
      refetchOnWindowFocus: false
    })

    const { data: publicity, isLoading: loadPublicity, refetch } = useQuery(['get-publicity'], {
      queryFn: getPublicity,
      refetchOnWindowFocus: false
    })

    const [selectedOrg, setSelectedOrg] = useState<string>("");

    const [openEdit, setOpenEdit ] = useState<boolean>( false )
    
    const [selectedPublicityItem, setSelectedPublicityItem] = useState<any | null>(null);

    const sortedPubli = !loadPublicity && publicity?.sort((a:any, b:any) => b?.id - a?.id)

    const handleOpenEdit = (publicityItem: any) => {
      setSelectedPublicityItem(publicityItem);
      setOpenEdit(true);
    };

    const filterPublicityByOrg = () => {
      if (selectedOrg && selectedOrg !== 'all') {
        return sortedPubli.filter((item:any) => item.org_id === selectedOrg);
      }
      return sortedPubli;
    };

    const organizacionMap = !loadOrgs && orgOptions?.reduce((acc:any, org:any) => {
      acc[org?.id] = org?.name;
      return acc;
    }, {});
    

    ///PAGINATION TABLE///
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

     // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
    !loadPublicity && page > 0 ? Math?.max(0, (1 + page) * rowsPerPage - publicity?.length) : 0;

    const handleChangePage = (
      event: React.MouseEvent<HTMLButtonElement> | null,
      newPage: number,
    ) => {
      setPage(newPage);
    };

    const handleChangeRowsPerPage = (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };

    const [ uploadImg, setUploadImg ] = useState<boolean>(false)

    const handleLoadinImgUpdate = (load:boolean) => {
      setUploadImg(load)
    }


  return (

    <>
    <FormCreatePublicity
      open={openEdit}
      handleClose={() => {
        setOpenEdit(false);
        setSelectedPublicityItem(null);
      }}
      refetch={refetch}
      handleUpdate={ () => {
        setOpenEdit(false);
        setSelectedPublicityItem(null);
      }}
      selectedPublicityItem={selectedPublicityItem}
      isEditMode={!!selectedPublicityItem}
      handleLoadinImgUpdate={handleLoadinImgUpdate}
    />
      <FormControl fullWidth={true}>

      <InputLabel id="filter-by-org"> Filtro por organización </InputLabel>
      <Select
        label="Filtro por organización"
        labelId="filter-by-org"
        value={selectedOrg}
        onChange={(e) => setSelectedOrg(e.target.value)}
        sx={{width:'25%'}}
      >
        <MenuItem value={'all'}>Todas las organizaciones</MenuItem>
        {!loadOrgs && orgOptions?.map((org:any) => (
          <MenuItem key={org.id} value={org.id}>
            {org.name}
          </MenuItem>
        ))}
      </Select>

      </FormControl>


    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Nombre</TableCell>
            <TableCell align="right">Organización</TableCell>
            <TableCell align="right">Descripcion</TableCell>
            <TableCell align="right">Link</TableCell>
            <TableCell align="right">Status</TableCell>
            <TableCell align="right">Editar</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          { 
          !loadPublicity && (rowsPerPage > 0
            ? filterPublicityByOrg()?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : filterPublicityByOrg()
          ).map((row:any) => (
            <TableRow
              key={row?.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <Tooltip title={ row?.title?.length > 30 && row?.title} disableHoverListener={ row?.title?.length <= 30 }>
                <TableCell component="th" scope="row">{row?.title?.length > 30 ? row?.title?.slice(0, 30) + "..." : row?.title}</TableCell>
              </Tooltip>
              <TableCell align="right" >
                  { organizacionMap[row?.org_id] }
              </TableCell>
              <Tooltip title={ row?.description?.length > 30 && row?.description} disableHoverListener={ row?.description?.length <= 30 }>
                <TableCell align="right">{row?.description?.length > 30 ? row?.description?.slice(0, 30) + "..." : row?.description}</TableCell>
              </Tooltip>
              <Tooltip title={ row?.link?.length > 30 && row?.link} disableHoverListener={ row?.link?.length <= 30 }>
                <TableCell align="right">{row?.link?.length > 30 ? row?.link?.slice(0, 30) + "..." : row?.link}</TableCell>
              </Tooltip>
              <TableCell align="right">{row?.status ? 'Activo' : 'Inactivo'} {row?.status ? <CheckCircleIcon color='success' /> : <BlockIcon color='error' />} </TableCell>
              <TableCell align="right">
                <IconButton onClick={() => handleOpenEdit(row)} disabled={uploadImg} >
                  <EditIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
          {emptyRows > 0 && (
            <TableRow style={{ height: 53 * emptyRows }}>
              <TableCell colSpan={6} />
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
              colSpan={3}
              count={!loadPublicity && filterPublicityByOrg()?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: {
                  'aria-label': 'filas por página',
                },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
    </>
  );
}