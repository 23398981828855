import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

export default (Component, admin) => {
    class ProtectedRoute extends React.Component {
        render() {
            let { me, initialized } = this.props;
            if (!initialized) {
                return null;
            }
            if (me !== null) {
                if (!me.is_initiated) {
                    return <Redirect to={{ pathname: "/activate" }} />;
                }
                if(admin){
                    if (me.is_admin) return (<Component {...this.props}/> );
                    else return (< Redirect to={{ pathname: "/" }} />);
                }
                return (<Component {...this.props}/> );
            }
            return (< Redirect to={{ pathname: "/login" }} />)
        }
    }
    const mapStateToProps = (state) => ({
        me: state.me.me,
        initialized: state.me.initialized,
    });
    return connect(mapStateToProps)(ProtectedRoute);
}
