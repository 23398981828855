import React from 'react'
import NewProductModal from '../../modals2.0/NewProductModal';
import {Row, Col, NavDropdown} from 'react-bootstrap';
import { FaEllipsisV } from 'react-icons/fa'
import { Scrollbars } from 'react-custom-scrollbars';
import { connect } from 'react-redux';
import store from '../../../redux/store';
import {getAllProducts} from '../../../service/api';
import {setProducts} from '../../../redux/actions';
import '../../styles/SelectedProduct.css'
import {UploadProductusByExcel} from "../../modals2.0/UploadProductsByExcel";

class ProductsTab extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            products: [],
            showEdit: false,
            editData: {},
            selectedProductIndex: null,
        }
        this.renderProducts = this.renderProducts.bind(this)
        this.fetchProducts = this.fetchProducts.bind(this)
    }

    async fetchProducts() {
        store.dispatch(setProducts([]));
        let page = 1
        let res = await getAllProducts(page)
        store.dispatch(setProducts(res['data']));
        while (res['next']) {
            page += 1
            res = await getAllProducts(page)
            const {products} = this.props;
            var _products = products.slice().concat(res['data'])
            store.dispatch(setProducts(_products));
        }
    }

    renderProducts() {
        const {products} = this.props;
        const {catalog} = this.props;
        const {termConditions} = this.props;
        const { selectedProductIndex } = this.state;
        const {city} = this.props;


        return products.map((product, index) => {
            const isSelected = selectedProductIndex === index;
            const productClassName = `clickable-product ${isSelected ? 'selected-product' : ''}`;
            return (
                <><div
                    key={product.id}
                    className={productClassName}
                >
                    <Row key={product.id} className="zeroMargin productsTableRow">
                        <Col className={`productsCatalogCol pl-z pr-s clickable`} onClick={() => this.handleEditClick(product, index)}>
                            {catalog.filter(cat => product.catalog.find(catalog_id => cat.id === catalog_id)).map(obj => obj.name).join(", ")}
                        </Col>
                        <Col className={`productsNameCol pl-z pr-s`} onClick={() => this.handleEditClick(product, index)}>
                            {product.name}
                        </Col>
                        <Col className={`productsSizeCol pl-z pr-s`} onClick={() => this.handleEditClick(product, index)}>
                            {product.size !== null && product.size.length > 0 ? product.size.join(", ") : "N/A"}
                        </Col>
                        <Col className={`productsSkuCol pl-z pr-s`} onClick={() => this.handleEditClick(product, index)}>
                            {product.id !== null ? product.id : "N/A"}
                        </Col>
                        <Col className={`productsCategoryCol pl-z pr-s`} onClick={() => this.handleEditClick(product, index)}>
                            {product.category.name}
                        </Col>
                        <Col className={`productsCostCol pl-z pr-s`} onClick={() => this.handleEditClick(product, index)}>
                            {product?.is_demon_price ? product?.prices[0]?.price : product?.price}
                        </Col>
                        <Col className={`productsDescriptionCol pl-z pr-s`} onClick={() => this.handleEditClick(product, index)}>
                            <div
                                dangerouslySetInnerHTML={{ __html: product.description }} />
                        </Col>
                        <Col className={`productsCategoryCol pl-z pr-s`} onClick={() => this.handleEditClick(product, index)}>
                            {termConditions.filter((tyc) => tyc.id === product.tyc_id).map(obj => obj.name)}
                        </Col>
                        <Col className={`productsCityCol pl-z pr-s clickable`} onClick={() => this.handleEditClick(product, index)}>
                            {city.filter(cit => product.city.find(city_id => cit.id === city_id)).map(obj => obj.city_name).join(", ")}
                        </Col>
                        <Col className="productsMenuCol pl-z pr-s">
                            <NavDropdown id={index} className="productsTableDropDown" drop="down" title={<FaEllipsisV className="hCentered" />}>
                                <Row className="ml-l pointer mb-s" onClick={() => this.handleEditClick(product, index)}>
                                    <span onClick={this.handleEditClick.bind(this, product)}>Edit</span>
                                </Row>
                            </NavDropdown>
                        </Col>
                    </Row>
                </div>
                </>

            )
        })
    }

    handleEditClick(product, index) {
        this.setState({showEdit: true, editData: product})
        this.setState({ selectedProductIndex: index });

    }

    handleEditClose() {
        this.setState({showEdit: false})
    }

    render() {
        const {showEdit, editData} = this.state;
        const {catalog} = this.props;
        const {termConditions} = this.props;
        const {categories} = this.props;
        const {city} = this.props;

        return(
                <>
                <NewProductModal
                    onAddition={this.fetchProducts}
                    catalog={catalog}
                    termConditions={termConditions}
                    categories={categories}
                    city={city}
                />
                <UploadProductusByExcel/>

                <div className="productsTableContainer">
                    <NewProductModal
                        edit={true}
                        data={editData}
                        _show={showEdit}
                        _handleClose={this.handleEditClose.bind(this)}
                        onAddition={this.fetchProducts}
                        catalog={catalog}
                        termConditions={termConditions}
                        categories={categories}
                        city={city}
                    />
                    <Row className="zeroMargin productsTableTitleRow subtitle">
                        <Col className="productsCatalogCol pl-z pr-s">
                            Catalogs
                        </Col>
                        <Col className="productsNameCol pl-z pr-s">
                            Name
                        </Col>
                        <Col className="productsSizeCol pl-z pr-s">
                            Size
                        </Col>
                        <Col className="productsSkuCol pl-z pr-s">
                            SKU #
                        </Col>
                        <Col className="productsCategoryCol pl-z pr-s">
                            Category
                        </Col>
                        <Col className="productsCostCol pl-z pr-s">
                            Cost($) | Points
                        </Col>
                        <Col className="productsDescriptionCol pl-z pr-s">
                            Description
                        </Col>
                        <Col className="productsCategoryCol pl-z pr-s">
                            TyC
                        </Col>
                        <Col className="productsCityCol pl-z pr-s">
                            Cities
                        </Col>
                        <Col className="productsMenuCol pl-z pr-s">
                            {/* menu */}
                        </Col>
                    </Row>
                    <Scrollbars className="productsScroll" >
                        {this.renderProducts()}
                    </Scrollbars>
                </div>  
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return({
        catalog: state.catalog.catalog,
        products: state.products.products,
        termConditions: state.termConditions.termConditions,
        categories: state.categories.categories,
        city: state.city.city
    });
} 

export default connect(mapStateToProps)(ProductsTab);
