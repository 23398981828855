import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Quill } from "react-quill";
import "../styles/RichText.sass"
import React from 'react';

interface RichTextProps {
    onContentChange: any;
    content?: string;
}

const CustomToolbar = () => (
    <div id="toolbar">
      <select className="ql-font">
        <option value="arial" selected>
          Arial
        </option>
        <option value="comic-sans">Comic Sans</option>
        <option value="courier-new">Courier New</option>
        <option value="georgia">Georgia</option>
        <option value="helvetica">Helvetica</option>
        <option value="lucida">Lucida</option>
      </select>
      <select className="ql-size">
        <option value="small">Size 1</option>
        <option value="medium" selected> Size 2 </option>
        <option value="large">Size 3</option>
      </select>
      <button className="ql-bold" />
      <button className="ql-italic" />
      <button className="ql-underline" />
      <button className="ql-list" />
      <select className="ql-color" />
      <button className="ql-clean" />
      
    </div>
  );
  
  const Size = Quill.import("formats/size");
  Size.whitelist = ["extra-small", "small", "medium", "large"];
  Quill.register(Size, true);
  
  // Add fonts to whitelist and register them
  const Font = Quill.import("formats/font");
  Font.whitelist = [
    "arial",
    "comic-sans",
    "courier-new",
    "georgia",
    "helvetica",
    "lucida"
  ];
  Quill.register(Font, true);

  const modules = {
    toolbar: {
      container: "#toolbar",
    }
  };

  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "color"
  ];

function RichText( { onContentChange, content }: RichTextProps ) {

    return(
        <>
            <div className="text-editor">
                  <CustomToolbar />
                  <ReactQuill 
                    theme="snow"
                    value={content || ''}
                    onChange={onContentChange}
                    modules={modules}
                    formats={formats}
                  />
            </div>
        </>
    )
}
export default RichText;