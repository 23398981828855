import React from 'react';
import { Row } from 'react-bootstrap';
import { activateGoalPeriod} from '../service/api';
import { Radio, Loader } from 'semantic-ui-react'

class OptionSwitch extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false
        }
        this.handleChangeActivateState = this.handleChangeActivateState.bind(this)
    }

    async handleChangeActivateState(data) {
        this.setState({loading: true})
        if (this.props.type === "period") {
            await activateGoalPeriod(this.props.data.id)
        }
        this.props.refresh()
        this.setState({loading: false})
    }

    render() {
        const {data} = this.props
        return(
            <Row className="zeroMargin optionSwitchRow fullWidth mb-m">
                <span className="vCenteredParent bold ml-m mr-auto">{data.name}</span>
                { this.state.loading &&
                    <Loader/>
                }
                { !this.state.loading &&
                    <Radio className="mt-s ml-auto mr-m" toggle checked={data.active} onClick={this.handleChangeActivateState} />
                }  
            </Row>
        )
    }
}

export default OptionSwitch;
