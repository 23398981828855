import React from 'react';
import {
    Box,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails, TableCell, TableRow
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
interface Question {
    question: string;
    answers: any[];
}

interface Props {
    data: Question[];
}

const RenderAnswers: React.FC<Props> = ({ data }) => {
    return (
        <Box>
            {data.length>0 && <Typography variant="h6" sx={{mb: 2}}>Respuestas</Typography>}
            {data.map((question, index) => (
                <Accordion key={index}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{m:0}}>
                        <Typography variant="subtitle1">{question.question}</Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{p:0}}>
                        <TableRow>
                            <TableCell sx={{fontWeight:'bold'}}>Fecha de respuesta</TableCell>
                            <TableCell sx={{fontWeight:'bold'}}>Respuesta</TableCell>
                        </TableRow>
                            {question.answers.map((answer, answerIndex) => (
                                <TableRow>
                                    <TableCell>
                                        <Typography variant="subtitle2" key={answerIndex}>{answer[1].split('T')[0]}</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="subtitle2" key={answerIndex}>{answer[0]}</Typography>
                                    </TableCell>
                                </TableRow>
                            ))}
                    </AccordionDetails>
                </Accordion>
            ))}
        </Box>
    );
};

export default RenderAnswers;