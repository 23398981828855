import React from 'react'
import {Row, Col, NavDropdown} from 'react-bootstrap';
import { connect } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';
import { FaEllipsisV } from 'react-icons/fa';
import NewCatalogModal from '../../modals/NewCatalogModal';
import '../../styles/CatalogTab.sass';
import {getCatalogs} from '../../../service/api';
import {setCatalogAction} from '../../../redux/actions';
import store from '../../../redux/store';
class CatalogTab extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            showEdit: false,
            editData: {}
        }
        this.renderCatalogs = this.renderCatalogs.bind(this)
    }

    renderCatalogs() {
        const {catalogs} = this.props;
        const categories = this.getCategories(catalogs)
        return catalogs.map((catalog, index) => {
            return (
                <>
                <Row key={index} className="zeroMargin productsTableRow">
                    <Col className="catalogNameCol pl-z pr-s">
                        {catalog.id}
                    </Col>
                    <Col className="catalogNameCol pl-z pr-s">
                        {catalog.name}
                    </Col>
                    <Col className="catalogCountCol alignCenter pl-z pr-s">
                        {catalog.products.length}
                    </Col>
                    <Col className="catalogCategoriesCol pl-z pr-s">
                        {categories[catalog.name].map(o => o.name).join(", ")}
                    </Col>
                    <Col className="catalogMenuCol pl-z pr-s">
                        <NavDropdown className="productsTableDropDown" drop="down" title={
                            <FaEllipsisV className="hCentered"/>
                        }>
                            <Row className="ml-l pointer mb-s">
                                <span onClick={this.handleEditClick.bind(this, catalog)}>Edit</span>
                            </Row>
                        </NavDropdown>
                    </Col>
                </Row>
                </>
            )
        })
    }

    handleEditClick(product) {
        this.setState({showEdit: true, editData: product})
    }

    handleEditClose() {
        this.setState({showEdit: false})
    }

    getCategories(catalogs) {
        let categories = {}
        catalogs.forEach(cat => {
            categories[cat.name] = []
            cat.products.forEach( p => {
                let c = categories[cat.name].find(_category => _category && _category.id === p.category.id)
                if (!c) {
                    categories[cat.name].push(p.category)
                }
            })
        })
        return categories
    }

    fetchCatalogs = async () => {
        const {editData, showEdit} = this.state;
        const catalogs = await getCatalogs()
        store.dispatch(setCatalogAction(catalogs));
        if (showEdit) {
            const catalog = catalogs.find(cat => cat.id === editData.id)
            if (catalog) this.setState({editData: catalog})
        }
    }

    render() {
        const {editData, showEdit} = this.state;
        return(
            <>
            <div className="catalogContainer">
                <NewCatalogModal edit={true} data={editData} _show={showEdit} _handleClose={this.handleEditClose.bind(this)} onAddition={this.fetchCatalogs}/>
                <NewCatalogModal onAddition={this.fetchCatalogs} />
                <div className="catalogTableContainer">
                    <Row className="zeroMargin productsTableTitleRow subtitle">
                        <Col className="catalogNameCol pl-z pr-s">
                            Id
                        </Col>
                        <Col className="catalogNameCol pl-z pr-s">
                            Name
                        </Col>
                        <Col className="catalogCountCol alignCenter pl-z pr-s">
                            # Products
                        </Col>
                        <Col className="catalogCategoriesCol pl-z pr-s">
                            Categories
                        </Col>
                        <Col className="catalogMenuCol pl-z pr-s">
                            {/* menu */}
                        </Col>
                    </Row>
                    <Scrollbars className="productsScroll" >
                            {this.renderCatalogs()}
                    </Scrollbars>
                </div>
            </div>
            </>
        );
    }

}

const mapStateToProps = (state) => ({
    catalogs: state.catalog.catalog
});

export default connect(mapStateToProps)(CatalogTab);
