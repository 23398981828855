import { SET_CATALOG } from './actions';

const INITIAL_STATE = {
  catalog: [],
};

export default function catalogReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_CATALOG:
      return { ...state, catalog: action.catalog };
    default:
      return state;
  }
}
