import React, {useEffect, useState} from "react";
import {activateSurvey, editCourse, getOrgCourses, getOrgs} from "../../../service/api";
import {Dropdown} from "semantic-ui-react";
import {Scrollbars} from "react-custom-scrollbars";
import CourseEditSwitch from "../../CourseEditSwitch";
import {Col, Row } from "react-bootstrap";


interface CourseItem {
    name: string;
    url: string;
    is_active: boolean;
    id: any;
    results_url?: string;
}

const Courses = () => {
    const [courses, setCourses] = useState([])
    const [orgs, setOrgs] = useState([] as any [])
    const [currentOrg, setCurrentOrg] = useState(null)

    useEffect( () => {
        const start = async () => {
            setOrgs( await getOrgs() )
        }
        start()
    }, [])

    const getOrgOptions = () => {
        return orgs.map((org, index) => {
            return {
                key: index,
                text: org.name,
                value: org.id
            }
        })
    }

    const handleOrgChange = (e: any, {value}: any) => {
        if (currentOrg === value) return
        setCurrentOrg( value )
        setCourses([])
        fetchCourses(value)
    }

   const fetchCourses = (value: any) => {
        setTimeout(() => {
            if (!value) { value = currentOrg }
            getOrgCourses(value).then(course => setCourses(course))
        }, 200)
    }

    const refreshCourses = async () => {
        if ( currentOrg ){
            const coursesData = await getOrgCourses( currentOrg )
            setCourses( coursesData )
        }
    }

    const renderCourses = () => {
        return courses.map( (course: CourseItem) => (
            <CourseEditSwitch
                editable={true}
                key={course.id}
                data={course}
                handleAction={ async (id: number) => {
                    await activateSurvey( id )
                    refreshCourses()
                } }
                save={ async (url: string, resultsUrl: string ) => {
                    await editCourse(course.id, { url, results_url: resultsUrl })
                    refreshCourses()
                } }
            />
        ) )
    }

    return(
        <div className="usersContainer pt-m">
            <span className="subtitle">Organization:</span>
            <Dropdown
                className="mb-xl mt-s"
                selection
                fluid
                options={getOrgOptions()}
                onChange={(e, a) => handleOrgChange(e,a)}
            />
            { currentOrg !== null &&
            <>
                <Row>
                    <Col xs={5}><span className="subtitle">Name</span></Col>
                    <Col xs={6}><span className="subtitle">Url</span></Col>
                    <Col><span className="subtitle">Active</span></Col>
                </Row>
                <Row className="usersTableContainer mt-xl">
                    <Scrollbars className="clientsScroll" >
                        {renderCourses()}
                    </Scrollbars>
                </Row>
            </>
            }
            { currentOrg === null &&
            <span className="ml-auto mr-auto mt-xxl title">Please select an org to see its users</span>
            }
        </div>
    )
}

export default Courses