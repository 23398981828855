import React, {useEffect, useState} from "react";
import {
    activateCustomAnalytics,
    editCustomAnalytic,
    getOrgCustomAnalytics,
    getOrgs,
    publicCustomAnalytics,
} from "../../../service/api";
import {Dropdown} from "semantic-ui-react";
import {Scrollbars} from "react-custom-scrollbars";
import NewCustomAnalytic from "../../modals/NewCustomAnalytic";
import CustomAnalyticsEditSwitch from "../../CustomAnalyticEditSwitch";

export interface CustomAnalyticsInterface {
    is_public: boolean | undefined;
    id: any;
    title: string;
    analytic_url: string;
    active: boolean;
    external_urls?: any;
}

const CustomAnalytics = () => {

    const [customAnalytics, setCustomAnalytics] = useState([])
    const [orgs, setOrgs] = useState([] as any [])
    const [currentOrg, setCurrentOrg] = useState(null)

    useEffect( () => {
        const start = async () => {
            setOrgs( await getOrgs() )
        }
        start()
    }, [])

    const getOrgOptions = () => {
        return orgs.map((org, index) => {
            return {
                key: index,
                text: org.name,
                value: org.id
            }
        })
    }

    const handleOrgChange = (e: any, {value}: any) => {
        if (currentOrg === value) return
        setCurrentOrg( value )
        setCustomAnalytics([])
        fetchCustomAnalytics(value)
    }

    const fetchCustomAnalytics = (value: any) => {
        setTimeout(() => {
            if (!value) {
                value = currentOrg
            }
            getOrgCustomAnalytics(value).then(customAnalytics => setCustomAnalytics(customAnalytics))
        }, 200)
    }

    const refreshCustomAnalytics = async () => {
        if ( currentOrg ){
            const CustomAnalyticsData = await getOrgCustomAnalytics( currentOrg )
            setCustomAnalytics( CustomAnalyticsData )
        }
    }

    function renderCustomAnalytics () {
        return customAnalytics.map( (customAnalytic: CustomAnalyticsInterface) => (
            <CustomAnalyticsEditSwitch
                key={customAnalytic.id}
                data={customAnalytic}
                handleAction={ async (id: number) => {
                    await activateCustomAnalytics( id )
                    refreshCustomAnalytics()
                } }
                handlePublicState={ async (id: number) => {
                    await publicCustomAnalytics( id )
                    refreshCustomAnalytics()
                } }
                save={ async (analyticUrl: string, externalUrls: string) => {
                    const external =  externalUrls ? { "calibrate": externalUrls } : null
                    await editCustomAnalytic(customAnalytic.id, { analytic_url: analyticUrl, external_urls: external })
                    refreshCustomAnalytics()
                } }
            />
        ) )
    }

    return (
        <div className="usersContainer pt-m">
            <span className="subtitle">Organization:</span>
            <Dropdown
                className="mb-xl mt-s"
                selection
                fluid
                options={getOrgOptions()}
                onChange={(e, a) => handleOrgChange(e,a)}
            />
            { currentOrg !== null &&
            <>
                <NewCustomAnalytic handleNew={fetchCustomAnalytics} organization_id={currentOrg} />
                <div className="usersTableContainer mt-xl">
                    <div style={{display:'flex',flexDirection:'row', width:'100%',justifyContent:'space-between'}}>
                        <span className="subtitle">Titulo</span>
                        <div>
                            <span className="subtitle mr-4">Estado</span>
                            <span className="subtitle">Publico</span>
                        </div>
                    </div>
                    <Scrollbars className="clientsScroll" >
                        {renderCustomAnalytics()}
                    </Scrollbars>
                </div>
            </>
            }
            { currentOrg === null &&
            <span className="ml-auto mr-auto mt-xxl title">Please select an org to see its users</span>
            }
        </div>
    )
}

export default CustomAnalytics;