import React from 'react'
import { searchUsers } from '../service/api'
import { Search, Grid } from 'semantic-ui-react'
import _ from 'lodash'
import {Image, Row} from 'react-bootstrap';

import './styles/SearchUsers.sass'

import { withRouter } from 'react-router-dom';

class SearchUsers extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            results: [],
            value: '',
            lastSearch: ''
        }
        this.handleResultSelect = this.handleResultSelect.bind(this);
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.resultRenderer = this.resultRenderer.bind(this);
    }

    handleResultSelect = (e, { result }) => {
        this.props.userselect(result)
        this.setState({value: ""})
    }

    resultRenderer = ({ name, id, imageURL, lastname }) =><Row key={id}><div><Image className="searchImage" src={imageURL} roundedCircle /></div><p className="searchLabel">{name} {lastname}</p></Row>

    handleSearchChange = async (e, { value }) => {
        value = value.replace(/^\s/g, '');
        value = value.replace(/\s{2,}/g, ' ');
        const {lastSearch} = this.state;
        const {me_id, filter_me} = this.props;
        if (value.length < 1 || lastSearch === value) return this.setState({value, results: []})
        this.setState({ isLoading: true, value })
        let users = await searchUsers({
            keywords:value,
            detailed:this.props.detailed,
            admin:this.props.admin,
            org:this.props.org
        })
        if (users.length < 1) return this.setState({
          isLoading: false,
          results: [],
          value: value
        })

        if (filter_me === true) {
            users = users.filter(u => u.id !== me_id)
        }

        this.setState({
            isLoading: false,
            results: users,
            lastSearch: value
        })
    }

    render() {
      const { isLoading, value, results } = this.state;
      return (
        <Grid>
          <Grid.Column width={6}>
            <Search
              loading={isLoading}
              onResultSelect={this.handleResultSelect}
              onSearchChange={_.debounce(this.handleSearchChange, 500, {
                leading: true,
              })}
              results={results}
              value={value}
              resultRenderer={this.resultRenderer}
              {...this.props}
            />
          </Grid.Column>
        </Grid>
      )
    }
}

export default withRouter(SearchUsers);
