import React, { useState } from 'react';
import {
    TextField,
    Button,
    Typography,
    Paper,
    Stack,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions, Alert
} from '@mui/material';
import {searchUsers,sendcourtesy} from "../../../service/api";

interface UserData {
    id: number;
    name: string;
    lastname: string;
    balance : {
       points_owned : number
    };
    org: {
        id: number;
        name: string;
    }
    dni: string;
}

const CourtesyPoints = () => {
    const [email, setEmail] = useState('');
    const [points, setPoints] = useState('0');
    const [userData, setUserData] = useState<UserData | null>(null);
    const [confirmation, setConfirmation] = useState(false);
    const [success, setSuccess] = useState(false);
    const [description_msg, setDescription] = useState('');
    const [emailError, setEmailError] = useState('');
    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newEmail = e.target.value;
        setEmail(newEmail);
        // Validación del formato de correo electrónico
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(newEmail)) {
            setEmailError('Ingrese un correo electrónico válido');
        } else {
            setEmailError('');
        }
    };

    const handlePointsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPoints(e.target.value);
    };

    const handleButtonClick = async () => {
        //const users = await searchUsers(email, true,false)
        const users = await searchUsers({
            keywords: email,
            detailed: true,
            admin: false,
            is_email: true
        })
        setUserData(users ? users[0] : []);
    };

    const handleDescription = (e: React.ChangeEvent<HTMLInputElement>) => {
        setDescription(e.target.value);
    }
    const handleSubmit =  async () => {
        const data={
            user_id:userData?.id,
            points:parseInt(points),
            description:description_msg
        }
        if (parseInt(points)>-1){
            sendcourtesy(data).then((res) =>{
                if (res){
                    setConfirmation(false)
                    setUserData(null)
                    setSuccess(true)
                    setEmail('')
                    setDescription('')
                    setPoints('0')
                    setTimeout(() =>setSuccess(false), 2000)
                }
            })
        }

    }
    const renderUserData = () => {
        if (userData) {
            return (

                <Stack alignItems="flex-start"  mt={1}  mb={1} direction={'column'}>
                    <Typography  variant={'subtitle1'}>
                        <span style={{fontWeight:'bold'}}>Organizacion </span>
                        {userData.org.name}
                    </Typography>
                    <Typography variant={'subtitle1'}>
                        <span style={{fontWeight:'bold'}}>Nombre: </span>
                        {userData.name} {userData.lastname}
                    </Typography>
                    <Typography  variant={'subtitle1'}>
                        <span style={{fontWeight:'bold'}}>DNI </span>
                        {userData.dni}
                    </Typography>
                    <Stack direction={'row'} alignItems={'center'} spacing={2}>
                        <Typography variant={'subtitle1'}  fontWeight={'bold'}> Puntos de cortesia: </Typography>
                        <TextField   value={points} type={'number'}  size={'small'} sx={{width:100,m:1}} onChange={handlePointsChange} />
                        <TextField
                            size={'small'}
                            multiline
                            minRows={2}
                            label={'Mensaje'}
                            variant={'outlined'}
                            value={description_msg}
                            onChange={handleDescription}
                        />
                        <Button variant={'contained'} onClick={()=>setConfirmation(true)}>Guardar</Button>
                    </Stack>
                </Stack>
            );
        }
    };

    return (
        <Paper elevation={2} sx={{mt:3,mb:3,p:2}}>
            {success && <Alert severity="success">Actualizacion existosa!</Alert>}
            <Dialog open={confirmation} onClose={()=>setConfirmation(false)}>
                <DialogTitle>Confirmar puntos</DialogTitle>
                <DialogContent>
                    Confirmar el envio de { points } puntos de cortesia a {userData?.name} {userData?.lastname}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleSubmit}>Confirmar</Button>
                    <Button onClick={()=>setConfirmation(false)}>Cancelar</Button>
                </DialogActions>
            </Dialog>
            <Typography variant={"h5"}  fontWeight={'bold'} pb={1}>Puntos de Cortesía</Typography>
            <Stack direction={'row'} spacing={2} >
                <TextField
                    size={'small'}
                    label="Correo electrónico"
                    value={email}
                    onChange={handleEmailChange}
                    error={Boolean(emailError)}
                    helperText={emailError}
                />
                <Button
                    disabled={Boolean(emailError)}
                    variant="contained"
                    onClick={handleButtonClick}>Buscar</Button>
            </Stack>
            {renderUserData()}
        </Paper>
    );
};

export default CourtesyPoints;
