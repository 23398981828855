import React from 'react';
import { Row, Col, Button, Modal, Form } from 'react-bootstrap';
import { Dropdown } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { addCity, editCity, removeProductFromCity } from '../../service/api';
import { Scrollbars } from 'react-custom-scrollbars';

import '../styles/NewCatalogModal.sass'

class NewCityModal extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            show: false,
            name: '',
            isLoading: false,
            products: [],
            country: 0
        }
        this.handleSave = this.handleSave.bind(this)
        this.handleShow = this.handleShow.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.renderProducts = this.renderProducts.bind(this)
    }

    handleShow = () => {
        this.setState({show: true})
    }

    componentWillReceiveProps(props) {
        const {data} = props
        if (data) {
            this.setState({name: data.city_name, country: data.country_id})
        }
    }

    getCountryOptions() {
        const {countries} = this.props;
        return countries.map((c) => ({
            key: c.id,
            text: c.name,
            value: c.id
        }))
    }

    handleClose = () => {
        this.setState({
            show: false,
            name: '',
            isLoading: false
        })
    }

    handleSave = async () => {
        this.setState({isLoading: true})
        const {name, country} = this.state
        const {edit, _handleClose, onAddition} = this.props
        if (edit !== true) {
            await addCity({name, country})
            onAddition()
        }
        else {
            const {data} = this.props;
            await editCity({name, country}, data.id)
            onAddition()
            _handleClose()
        }
        this.handleClose()
    }

    renderProducts() {
        const {data} = this.props
        if (!data || !data.products) return null
        return data.products.map((product, index) => {
            return(
                <Row key={index} className="zeroMargin newCatalogModalTableRow">
                    <Col className="pl-z pr-m newCatalogModalNameCol">
                        {product.name}
                    </Col>
                    <Col className="pl-z pr-m newCatalogModalCategoryCol">
                        {product.category.name}
                    </Col>
                    <Col className="pl-z pr-m newCatalogModalPriceCol">
                        {product.price}
                    </Col>
                    <Col className="pl-z pr-m alignRight newCatalogModalDeleteCol">
                        <span onClick={this.handleRemoveProduct.bind(this, product.id)} className="pointer alignRight lightPrimary">X</span>
                    </Col>
                </Row>
            )
        })
    }

    async handleRemoveProduct(id) {
        const {onAddition, data} = this.props
        await removeProductFromCity({product_ids: [id], city_ids: data.id})
        onAddition()
    }

    render() {
        const {show, name} = this.state
        const {edit, _show, _handleClose} = this.props;
        return(
            <>  { edit !== true &&
                    <span className="ml-auto mb-l newCityModalShow pointer" onClick={this.handleShow}>Create New City</span>
                }
                <Modal show={edit === true ? _show : show} onHide={edit === true ? _handleClose : this.handleClose} centered={true}>
                    <div className="newCatalogModalContainer">
                        <Row className="zeroMargin mb-m subtitle">
                            City Name:
                        </Row>
                        <Row className="zeroMargin mb-xxl">
                            <Form.Control style={{maxWidth: 365}} type="text" placeholder="" value={name} onChange={(e) => this.setState({ name: e.target.value })} />
                            <Button onClick={this.handleSave} className="saveButton ml-auto">Save</Button>
                        </Row>

                        <Dropdown
                        className="cityDropdown"
                        selection
                        fluid
                        defaultValue={this.state.country}
                        options={this.getCountryOptions()}
                        onChange={(e, {value}) => this.setState({country: value})}
                    />

                        { edit === true &&
                            <>
                                <Row className="mt-l subtitle newCatalogModalTableTitleRow zeroMargin">
                                    <Col className="pl-z pr-m newCatalogModalNameCol">
                                        Product Name
                                    </Col>
                                    <Col className="pl-z pr-m newCatalogModalCategoryCol">
                                        Category
                                    </Col>
                                    <Col className="pl-z pr-m newCatalogModalPriceCol">
                                        Price
                                    </Col>
                                </Row>
                                <Scrollbars style={{height: 400}}>
                                    {this.renderProducts()}
                                </Scrollbars>
                            </>
                        }

                    </div>
                </Modal>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return({
        countries: state.countries.countries,
    });
} 

export default connect(mapStateToProps)(NewCityModal)
