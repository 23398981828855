import React from 'react';
import '../../styles/CategoriesTab.sass';
import {Row, Col, NavDropdown} from 'react-bootstrap';
import { connect } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';
import NewCategoryModal from '../../modals/NewCategoryModal';
import { FaEllipsisV } from 'react-icons/fa';

class CategoriesTab extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            showEdit: false,
            editCat: {}
        }
        this.renderCategories = this.renderCategories.bind(this);
        this.handleHideEdit = this.handleHideEdit.bind(this)
    }

    renderCategories = () => {
        const {categories} = this.props;
        return categories.map((cat, index) => {
            return(
                <Row key={index} className="zeroMargin productsTableRow">
                    <Col className="categoriesIDCol pl-z pr-s">
                        {cat.id}
                    </Col>
                    <Col className="categoriesNameCol alignCenter pl-z pr-s">
                        {cat.name}
                    </Col>
                    <Col className="categoriesMenuCol pl-z pr-s">
                        <NavDropdown className="productsTableDropDown" drop="down" title={
                            <FaEllipsisV className="hCentered"/>
                        }>
                            <Row className="ml-l pointer mb-s">
                                <span onClick={this.handleEditClick.bind(this, cat)}>Edit</span>
                            </Row>
                        </NavDropdown>
                    </Col>
                </Row> 
            );
        })
    }

    handleEditClick = (cat) => {
        this.setState({showEdit: true, editCat: cat})
    }

    handleHideEdit = () => {
        this.setState({showEdit: false, editCat: {}})
    }

    render() {
        const {editCat, showEdit} = this.state;
        return(
            <div className="categoriesContainer">
                <NewCategoryModal edit={true} _show={showEdit} _handleClose={this.handleHideEdit} category={editCat}/>
                <NewCategoryModal />
                <div className="categoriesTableContainer">
                    <Row className="zeroMargin productsTableTitleRow subtitle">
                        <Col className="categoriesIDCol pl-z pr-s">
                            ID
                        </Col>
                        <Col className="categoriesNameCol alignCenter pl-z pr-s">
                            Name
                        </Col>
                        <Col className="categoriesMenuCol pl-z pr-s">
                            {/* menu */}
                        </Col>
                    </Row>
                    <Scrollbars className="productsScroll" >
                            {this.renderCategories()}
                    </Scrollbars>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return({
        categories: state.categories.categories
    });
} 

export default connect(mapStateToProps)(CategoriesTab);
