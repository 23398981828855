import { SET_CITY } from './actions';

const INITIAL_STATE = {
  city: [],
};

export default function cityReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_CITY:
      return { ...state, city: action.city };
    default:
      return state;
  }
}
