import React, {useEffect, useState} from 'react';
import {addProduct, editProduct, getForms} from '../../service/api';
import {upload_file} from "../../helpers/upload";
import {
    Alert,
    Box,
    Button,
    Checkbox,
    Container,
    Divider, FormControl, FormControlLabel, FormHelperText,
    InputLabel, ListItemText, Select, SelectChangeEvent,
    Stack,
    TextField,
    Typography,
    IconButton,
    Modal
} from "@mui/material";
import MenuItem from '@mui/material/MenuItem';
import Upload from '../Upload';
import DeleteIcon from '@mui/icons-material/Delete';
import ProductCardPreview from './ProductCardPreview'

interface newProductProps {
    _handleClose?: any;
    edit?: boolean ;
    _show?: any;
    data?: any;
    onAddition?:any;
    catalog?:any;
    termConditions?:any;
    categories?:any;
    city?:any
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1080,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    height: '80%',
    overflowY: 'scroll'
  };

const NewProductModal: React.FC<newProductProps> = (props) => {

    const [show, setShow] = useState(false)

    const [name, setName] = useState('')
    const [description, setDescription] = useState('')
    const [sku, setSku] = useState('')
    const [vendor, setVendor] = useState('')
    const [catalogSelections, setCatalogSelections] = useState<number[]>([]);
    const [sizeSelections, setSizeSelections] = useState<any | null>([])
    const [category, setCategory] = useState<any | null>('')
    const [price, setPrice] = useState<any>(null)
    const [featured, setFeatured] = useState(false)
    const [filePreview, setFilePreview] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [file, setFile] = useState(null)
    const [tyc_id, setTyc_id] = useState('')
    const [is_corporate_benefit, setIs_corporate_benefit] = useState(false)
    const [is_demon_price, setIs_demon_price] = useState(false)
    const [forms, setForms] = useState([])
    const [form_id, setForm_id] = useState('')
    const [citySelections, setCitySelections] = useState<number[]>([]);


    let attachmentType ='product-image'

    useEffect(() => {
        getForms().then(res => {
            setForms(res)
        })
    }, [])
    const handleShow = () =>{
        setShow(true)
    }

    const handleMessageChange = (e:any) =>{
        if(e?.target?.value?.length<=500){
            setDescription(e?.target?.value)
        }
    }

    const handleClose = () =>{
        const {_handleClose, edit} = props
        setName('')
        setDescription('')
        setSku('')
        setVendor('')
        setCatalogSelections([])
        setSizeSelections([])
        setCategory('')
        setPrices([
            { key: 0, price: 0, dollar_price: 0 },
          ])
        setPrice(null)
        setFilePreview('')
        setFeatured(false)
        setFile(null)
        setIsLoading(false)
        setTyc_id('')
        setCitySelections([])
        setIs_corporate_benefit(false)

        if (edit) {
            _handleClose()
        } else {
            setShow(false)
        }
    }

    const handleSave = async () =>{
        const {edit, data} = props
        setIsLoading(true)
        if (!edit) {
            const uploadData = {
                // @ts-ignore
                file_name: file?.name
            }
            const attachmentUrl = await upload_file(attachmentType, uploadData, file)
            if (attachmentUrl) {
                await addProduct([{
                    name: name,
                    prices: prices,
                    price: price,
                    category: category,
                    description: description,
                    featured: featured,
                    vendor_name: vendor,
                    size: sizeSelections,
                    catalog_ids: catalogSelections,
                    imageURL: attachmentUrl['url'] + attachmentUrl['key'],
                    tyc_id: tyc_id,
                    form_id: form_id,
                    is_corporate_benefit: is_corporate_benefit,
                    is_demon_price: is_demon_price,
                    city_ids: citySelections
                }])
            }
            const {onAddition} = props
            onAddition()
            handleClose()
        }else{
            let attachmentUrl
            if (filePreview !== '' && file){
                const uploadData = {
                    // @ts-ignore
                    file_name: file?.name
                }
                setIsLoading(true)
                attachmentUrl = await upload_file(attachmentType, uploadData, file)
            }

            let _data = {
                name: name === '' ? null : name,
                prices: prices,
                price: price,
                category: category === '' ? null : category,
                description: description === '' ? null : description,
                featured: featured,
                vendor_name: vendor === '' ? null : vendor,
                size: sizeSelections,
                imageURL: !attachmentUrl ? null : attachmentUrl['url']+attachmentUrl['key'],
                catalog_ids: catalogSelections,
                tyc_id: tyc_id === '' ? null : tyc_id,
                form_id: form_id === '' ? null : form_id,
                is_corporate_benefit: is_corporate_benefit,
                is_demon_price: is_demon_price,
                city_ids: citySelections
            }
            if (attachmentUrl){
                _data.imageURL = attachmentUrl['url']+attachmentUrl['key']
            }
            await editProduct(_data, data.id)
            const {onAddition} = props
            setIsLoading(false)
            onAddition()
            handleClose()
        }
    }

    function getCatalogOptions(){
        const { catalog } = props;
        if (catalog && Array.isArray(catalog)) {
            const options = catalog.map((cat: any) => {
                return { name: cat.name, id: cat.id };
            });
            return options.map((value: any, index: any) => {
                return {
                    key: index,
                    text: value.name,
                    value: value.id,
                };
            });
        }
        return [];
    }

    function getCityOptions(){
        const { city } = props;
        if (city && Array.isArray(city)) {
            const options = city.map((cit: any) => {
                return { name: cit.city_name, id: cit.id };
            });
            return options.map((value: any, index: any) => {
                return {
                    key: index,
                    text: value.name,
                    value: value.id,
                };
            });
        }
        return [];
    }

    function getTyCOptions(){
        let{termConditions} = props || []
        termConditions = termConditions ? termConditions : []
        const options = termConditions.map((tyc:any) => {
            return {name: tyc.name, id: tyc.id}
        })
        return options.map((value:any, index:any)=>{
            return{
                key: index,
                text: value.name,
                value: value.id
            }
        })
    }


    function getSizeOptions(){
        const options = ["s", "m", "l", "xl"]
        return options.map((value, index) => {
            return {
                key: index,
                text: value,
                value: value
            }
        })
    }

    function getCategoriesOptions(){
        const {categories} = props
        if (categories && Array.isArray(categories)) {
            const options = categories.map((cat:any) => {
                return {name: cat.name, id: cat.id}
            })
            return options.map((value:any, index:any)=>{
                return{
                    key: index,
                    text: value.name,
                    value: value.id
                }
            })
        }
        return [];
    }

    function handleSizeChange(e:any, value:any){
        setSizeSelections(value.props.value)
    }

    const handleCatalogChange = (event: SelectChangeEvent<number[]>) => {
        const selectedOptions = event.target.value as number[];
        setCatalogSelections(selectedOptions);
    };

    const handleCityChange = (event: SelectChangeEvent<number[]>) => {
        const selectedOptions = event.target.value as number[];
        setCitySelections(selectedOptions);
    };

    function handleTyCChange(e:any, value:any){
        setTyc_id(value.props.value)
    }

    function handleFormChange(e:any, value:any){
        setForm_id(value.props.value)
    }

    function handleCategoryChange(e:any, value:any){
        setCategory(value.props.value)
    }

    /* function handlePointsChange (e:any) {
        if (parseInt(e.target.value) < 0) return
        setPointsCost(e.target.value)
        if (e.target.value !== ''){
            setPointsCost('')
            setDollarCost('')
            setPrice(parseInt(e.target.value))
            setPrices(
                // @ts-ignore
                prices.concat([{
                    key: prices.length,
                    price: parseInt(e.target.value),
                    dollar_price: dollarCost !== ''? parseInt(dollarCost) : 0
                }])
            )
        }
    } */

    const [prices, setPrices] = useState<{ key: number; price: number; dollar_price: number }[]>([
        { key: 0, price: 0, dollar_price: 0 },
    ]);

    const somePriceCeroOrNaN = prices?.some((obj:any) => obj?.price === 0 || isNaN(obj?.price));

    const handlePointsChange = (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
        const newPrices = [...prices];
        const newPrice = parseInt(e.target.value);
    
        if (newPrice < 0) return;

        setPrice(parseInt(e.target.value))
    
        newPrices[index] = {
          ...newPrices[index],
          price: newPrice,
        };
    
        setPrices(newPrices);
      };
    
    const addPriceField = () => {
    setPrices([
        ...prices,
        { key: prices.length, price: 0, dollar_price: 0 },
    ]);
    };

    const handleRemovePriceField = (indexToRemove: number) => {
        setPrices(prevPrices => prevPrices.filter((_, index) => index !== indexToRemove));
    };

    function fileUploadHandler(file:any){
        const preview = URL.createObjectURL(file)
        setFile(file)
        setFilePreview(preview)
    }

    const {edit, _show, data, _handleClose} = props

    useEffect(()=> {
        if (edit) {
            if (data) {
                setName(data.name)
                setDescription(data.description)
                setSku(data.id)
                setVendor(data.vendor_name)
                setCatalogSelections(data.catalog)
                setSizeSelections(data.size)
                setCategory(data.category?.id)
                setPrices(data.prices ? data.prices.map((price:any, key:any) => ({ key, ...price })) : [])
                setPrice(data.price)
                setFeatured(data.featured)
                setTyc_id(data.tyc_id)
                setForm_id(data.form_id)
                setIs_corporate_benefit(data.is_corporate_benefit)
                setFilePreview(data.imageURL)
                setIs_demon_price(is_demon_price)
                setCitySelections(data.city)
            }
        }

    },[edit, data, is_demon_price])


    return(
        <>
            {
                edit !== true &&
                <Button variant={"text"} onClick={handleShow}> Add New Product</Button>
            }
            <Modal
                open={edit ? _show : show}
                onClose={edit ? _handleClose : handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                    <Box sx={style}>
                    <Typography>Add New Product</Typography>
                    <Stack
                        direction={"row"}
                        divider={<Divider orientation="vertical" flexItem />}
                        spacing={2}
                    >
                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { m: 1, width: '50ch' },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <TextField
                                required
                                id="outlined-required"
                                label="Product Name"
                                helperText={`${name?.length} / 42 caracteres`}
                                value={name}
                                onChange={(e) => e.target.value.length <= 42 ? setName(e.target.value) : null}
                            />
                            <Typography variant={'subtitle1'} > Product Description: </Typography>
                            {/* <RichText
                                content={description}
                                onContentChange={handleMessageChange}
                            /> */}
                            <TextField
                                required
                                id="outlined-required"
                                label="Description"
                                value={description}
                                rows={4}
                                multiline
                                onChange={handleMessageChange}
                                helperText={`${description?.length} / 500 caracteres`}
                            />

                            {description?.length >= 500 && (
                                <Alert variant="outlined" severity="error">
                                    ¡Superaste el límite de caracteres!
                                </Alert>
                            )}

                            <Divider orientation="horizontal" flexItem sx={{margin: '10px 0px'}} />

                            <Stack
                                direction="row"
                                justifyContent="space-evenly"
                                alignItems="stretch"
                                spacing={2}
                            >
                                <FormControl>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={featured}
                                                onChange={(e) => setFeatured(e.target.checked)}
                                            />
                                        }
                                        label="Featured"
                                    />
                                </FormControl>
                                <FormControl>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={is_corporate_benefit}
                                                onChange={(e) => setIs_corporate_benefit(e.target.checked)}
                                            />
                                        }
                                        label="Corporate Benefit"
                                    />
                                </FormControl><FormControl>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={is_demon_price}
                                                onChange={(e) => setIs_demon_price(e.target.checked)}
                                            />
                                        }
                                        label="Demon price"
                                    />
                                </FormControl>
                            </Stack>
                            <Divider orientation="horizontal" flexItem />
                            <Stack
                                direction={{ xs: 'column', sm: 'row' }}
                                spacing={{ xs: 1, sm: 2, md: 4 }}
                                justifyContent="space-between"
                                alignItems="stretch"
                                sx={{
                                    marginTop: '20px',
                                }}
                            >
                                <FormControl>
                                    <InputLabel id="catalog-label">Catalog</InputLabel>
                                    <Select
                                        labelId="catalog-label"
                                        id="catalog"
                                        multiple
                                        value={catalogSelections}
                                        onChange={handleCatalogChange}
                                        renderValue={(selected) => (
                                            <div>
                                                {(selected as number[]).map((value) => (
                                                    <ListItemText key={value} primary={getCatalogOptions().find((option) => option.value === value)?.text || ''} />
                                                ))}
                                            </div>
                                        )}
                                    >
                                        {getCatalogOptions().map((option) => (
                                            <MenuItem key={option.key} value={option.value}>
                                                <Checkbox checked={(catalogSelections || []).includes(option.value)} />
                                                <ListItemText primary={option.text} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <FormControl  sx={{ m: 1, minWidth: 120 }}>
                                    <InputLabel id="size-label">Size</InputLabel>
                                    <Select
                                        labelId="size-label"
                                        id="size"
                                        value={`${sizeSelections}`}
                                        label="Size"
                                        onChange={handleSizeChange}
                                    >
                                        <MenuItem value=""></MenuItem>
                                        {getSizeOptions().map((option) => (
                                            <MenuItem key={option.key} value={option.value}>{option.text}</MenuItem>
                                        ))}

                                    </Select>
                                </FormControl>
                                <FormControl required sx={{ m: 1, minWidth: 120 }}>
                                    <InputLabel id="productCategorie-label">Produc Categorie</InputLabel>
                                    <Select
                                        labelId="productCategorie-label"
                                        id="productCategorie"
                                        value={`${category}`}
                                        label="Produc Categorie"
                                        onChange={handleCategoryChange}
                                    >
                                        <MenuItem value=""></MenuItem>
                                        {getCategoriesOptions().map((option: any) => (
                                            <MenuItem key={option.key} value={option.value}>{option.text}</MenuItem>
                                        ))}

                                    </Select>
                                    <FormHelperText>Required</FormHelperText>
                                </FormControl>
                            </Stack>
                            {props.edit && <TextField
                                id="outlined-required"
                                label="SKU"
                                value={sku}
                                disabled={edit}
                            />}

                            <Stack
                                direction={'row'}
                                spacing={0.1}
                                justifyContent="space-between"
                                alignItems="stretch"
                                sx={{
                                    marginTop: '20px',
                                    display: 'flex',
                                    flexDirection: 'column'
                                }}
                            >
                                {prices.map((item, index) => (
                                    <div key={item.key} style={{display: 'flex', alignItems: 'center'}}>
                                    <TextField
                                        required
                                        id={`outlined-required-${index}`}
                                        label={`Cost in Points ${index + 1}`}
                                        type="number"
                                        value={item.price}
                                        onChange={handlePointsChange(index)}
                                    />
                                    <IconButton
                                        aria-label="delete"
                                        onClick={() => handleRemovePriceField(index)}
                                        disabled={prices?.length === 1}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                    </div>
                                ))}

                                { somePriceCeroOrNaN && (
                                    <Typography color='error' sx={{paddingLeft: '20px'}}>
                                        Los precios deben ser mayores a cero
                                    </Typography>
                                )}

                                <Button variant="contained" onClick={addPriceField} disabled={!is_demon_price} sx={{
                                    width: '27%',
                                    transform: 'translateX(10px)',
                                    textTransform: 'capitalize'
                                    }} >
                                    Agregar precio
                                </Button>
                            </Stack>
                            {/* <Paper
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    flexWrap: 'wrap',
                                    listStyle: 'none',
                                    p: 0.5,
                                    m: 0,
                                }}
                                component="ul"
                            >
                                {prices?.map((price:any, key:any) => {
                                    return (
                                       <Chip
                                            key={key}
                                            label={price.price}
                                            onDelete={()=>handleDeletePrice({key, price})}
                                       />

                                    );
                                })}
                            </Paper> */}
                            <TextField
                                id="outlined-required"
                                label="Vendor Name"
                                value={vendor}
                                onChange={(e:any) => setVendor(e.target.value)}
                            />
                            <Stack
                                direction={'row'}
                                spacing={0.1}
                                justifyContent="space-between"
                                alignItems="stretch"
                            >
                                <Typography variant={'subtitle1'} > Upload Photo(s) </Typography>
                                <Upload upload={false} fileUploadHandler={fileUploadHandler} />
                            </Stack>
                            <FormControl required sx={{ m: 1, minWidth: 400, marginTop: 2}}>
                                <InputLabel id="tyc-label">TyC</InputLabel>
                                <Select
                                    labelId="tyc-label"
                                    id="tyc"
                                    value={`${tyc_id}`}
                                    label="TyC"
                                    onChange={handleTyCChange}
                                >
                                    <MenuItem value=""></MenuItem>
                                    {getTyCOptions().map((option:any) => (
                                        <MenuItem key={option.key} value={option.value}>{option.text}</MenuItem>
                                    ))}

                                </Select>
                                <FormHelperText>Required</FormHelperText>
                            </FormControl>
                            <FormControl sx={{ m: 1, minWidth: 400, marginTop: 2}}>
                                <InputLabel id="form-label">Formulario</InputLabel>
                                <Select
                                    labelId="form-label"
                                    id="form"
                                    value={`${form_id}`}
                                    label="Formulario"
                                    onChange={handleFormChange}
                                >
                                    <MenuItem value=""></MenuItem>
                                    {forms.map((option:any) => (
                                        <MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>
                                    ))}

                                </Select>
                                <FormHelperText>Opcional</FormHelperText>
                            </FormControl>
                            <FormControl sx={{ m: 1, minWidth: 400, marginTop: 2}}>
                                <InputLabel id="city-label">Cities</InputLabel>
                                <Select
                                    labelId="city-label"
                                    id="city"
                                    multiple
                                    value={citySelections}
                                    onChange={handleCityChange}
                                    renderValue={(selected) => (
                                        <div>
                                            {(selected as number[]).map((value) => (
                                                <ListItemText key={value} primary={getCityOptions().find((option) => option.value === value)?.text || ''} />
                                            ))}
                                        </div>
                                    )}
                                >
                                    {getCityOptions().map((option) => (
                                        <MenuItem key={option.key} value={option.value}>
                                            <Checkbox checked={(citySelections || []).includes(option.value)} />
                                            <ListItemText primary={option.text} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                        <Container maxWidth="xl" sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            {/* <ProductCard data={{price: price, imageURL: filePreview, name: name, description: description}} /> */}
                            <ProductCardPreview price={price} image={filePreview} title={name} description={description} />
                        </Container>
                    </Stack>
                    
                    <Button disabled={isLoading || name?.length === 0 || description?.length === 0 || category === "" || catalogSelections?.length === 0 || tyc_id === '' || somePriceCeroOrNaN } className="ml-auto mr-m" onClick={handleSave} variant='contained' >Guardar</Button>

                </Box>
            </Modal>
        </>
    )
}

export default NewProductModal;
