import React from 'react';
import { Dropdown } from 'semantic-ui-react';
import { Row, Col, Button, Modal, Form } from 'react-bootstrap';
import { createNewUser, updateUser } from '../../service/api';
import '../styles/EditUserAdminModal.sass';

class EditUserAdminModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            name: '',
            lastname: '',
            email: '',
            phone: '',
            address: '',
            title: '',
            type: 'User',
            status: 'Active'
        };
        this.handleClose = this.handleClose.bind(this);
        this.handleShow = this.handleShow.bind(this);
    };

    userTypeOptions = [
        {
            key: 'user',
            text: 'User',
            value: 'User'
        },
        {
            key: 'admin',
            text: 'Admin',
            value: 'Admin'
        },
        {
            key: 'manager',
            text: 'Manager',
            value: 'Manager'
        }
    ];

    userStatusOptions = [
        {
            key: 'Active',
            text: 'Active',
            value: 'Active'
        },
        {
            key: 'Inactive',
            text: 'Inactive',
            value: 'Inactive'
        }
    ];

    handleShow = () => {
        this.setState({ show: true });
    };

    getUserType = (user) => {
        if (user.is_admin) return 'Admin';
        if (user.is_manager) return 'Manager';
        return 'User';
    }

    componentWillReceiveProps = (props) => {
        const {user} = props;
        if (user) {
            if (user){
                const { name, lastname, email, phone, address, title, is_active } = user
                this.setState({ name, lastname, email, phone, address, title, type: this.getUserType(user), status: is_active ? 'Active' : 'Inactive' })
            }
        }
    }

    handleClose = () => {
        const {newUser} = this.props;
        if (!newUser) {
            const { name, lastname, email, phone, address, title } = this.props.user;
            this.setState({ show: false, name, lastname, email, phone, address, title });
        }
        else {
            this.setState({ show: false, name:'', lastname: '', email: '', phone: '', address: '', title:'' });
        }
    };

    handleCancelButton = async () => {
        this.handleClose()
    }

    handleSaveButton = async () => {
        const { name, lastname, email, phone, address, title, type } = this.state;
        const { id } = this.props.user;
        let {status} = this.state;
        status = status === "Active" ? true : false
        updateUser({ name, lastname, email, phone, address, title, type, status }, id)
        this.props.fetchUsers()       
        this.props._handleClose()
    }

    handleNewUser = async () => {
        const {org_id} = this.props;
        const { name, lastname, email, phone, address, title, type } = this.state;
        let {status} = this.state;
        status = status === "Active" ? true : false
        let data = { name, lastname, email, phone, address, title, type, status, organization_id: org_id }
        await createNewUser(data)
        // getOrgUsers()
        this.props.fetchUsers()       
        this.handleClose()
    }

    render() {
        const { name, lastname, email, phone, address, title, type, status } = this.state;
        const { newUser, _show, _handleClose } = this.props;
        return (
            <>
            {
                newUser === true &&
                <span className="pointer lightPrimary ml-auto subtitle" onClick={this.handleShow}>Create New User</span>
            }
            <Modal show={newUser === true ? this.state.show : _show} onHide={newUser === true ? this.handleClose : _handleClose} centered={true}>
                <Row className="zeroMargin">
                    <span className="editUserAdminCloseButton pointer" onClick={newUser === true ? this.handleClose : _handleClose}>x</span>
                </Row>
                <Row className="zeroMargin title mb-xl">
                    <Col className="pl-xxl pr-xxl">
                        Account
                    </Col>
                </Row>
                <Row className="zeroMargin mb-l">
                    <Row className="zeroMargin mb-m">
                        <Col className="pl-xxl editUserModalLeftCol">
                            Name
                        </Col>
                        <Col className="pl-xxl editUserModalRightCol">
                            <Form.Control
                                value={name}
                                onChange={(e) => this.setState({ name: e.target.value })}
                            />
                        </Col>
                    </Row>
                    <Row className="zeroMargin mb-m">
                        <Col className="pl-xxl editUserModalLeftCol">
                            Last Name
                        </Col>
                        <Col className="pl-xxl editUserModalRightCol">
                            <Form.Control
                                value={lastname}
                                onChange={(e) => this.setState({ lastname: e.target.value })}
                            />
                        </Col>
                    </Row>
                    <Row className="zeroMargin mb-m">
                        <Col className="pl-xxl editUserModalLeftCol">
                            Email
                        </Col>
                        <Col className="pl-xxl editUserModalRightCol">
                            <Form.Control
                                value={email}
                                onChange={(e) => this.setState({ email: e.target.value })}
                            />
                        </Col>
                    </Row>
                    <Row className="zeroMargin mb-m">
                        <Col className="pl-xxl editUserModalLeftCol">
                            Phone
                        </Col>
                        <Col className="pl-xxl editUserModalRightCol">
                            <Form.Control
                                value={phone}
                                onChange={(e) => this.setState({ phone: e.target.value })}
                            />
                        </Col>
                    </Row>
                    <Row className="zeroMargin mb-m">
                        <Col className="pl-xxl editUserModalLeftCol">
                            Address
                        </Col>
                        <Col className="pl-xxl editUserModalRightCol">
                            <Form.Control
                                value={address}
                                onChange={(e) => this.setState({ address: e.target.value })}
                            />
                        </Col>
                    </Row>
                    <Row className="zeroMargin mb-m">
                        <Col className="pl-xxl editUserModalLeftCol">
                            Title
                        </Col>
                        <Col className="pl-xxl editUserModalRightCol">
                            <Form.Control
                                value={title}
                                onChange={(e) => this.setState({ title: e.target.value })}
                            />
                        </Col>
                    </Row>
                    <Row className="zeroMargin mb-xxl">
                        <Col className="pl-xxl editUserModalLeftCol">
                            Type of User
                        </Col>
                        <Col className='pl-xxl editUSerModalRightCol'>
                            <Dropdown
                                fluid
                                selection
                                options={this.userTypeOptions}
                                defaultValue={type}
                                onChange={(e, {value}) => this.setState({type: value.toLowerCase()})}
                            />
                        </Col>
                        <Col className="pl-xxl editUserModalLeftCol">
                            Status
                        </Col>
                        <Col className='pl-xl editUSerModalRightCol'>
                            <Dropdown
                                fluid
                                selection
                                options={this.userStatusOptions}
                                defaultValue={status}
                                onChange={(e, {value}) => this.setState({status: value.toLowerCase()})}
                            />
                        </Col>
                    </Row>
                </Row>
                <Row className="zeroMargin mb-xxl">
                        <Button className=" ml-auto mr-m editUserModalCancelButton" onClick={this.handleCancelButton.bind(this)}>Cancel</Button>
                        <Button className="mr-xxl editUserModalSaveButton" onClick={newUser ? this.handleNewUser.bind(this) : this.handleSaveButton.bind(this)}>{newUser? 'Create' : 'Save'}</Button>
                </Row>
            </Modal>
            </>
        );
    }
}

export default (EditUserAdminModal)
