import React from 'react';
import Products from './pages/products/Products';
import Login from './pages/Login';
import store from '../redux/store';
import ForgotPassword from './pages/ForgotPassword';
import Orders from './pages/orders/Orders'
import Publicity from './pages/publicity/Publicity'
import Clients from './pages/clients/Clients';
import Users from './pages/users/Users';
import Goals from './pages/goals/Goals';
import Surveys from './pages/surveys/Surveys';
import Courses from './pages/courses/Courses';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
} from 'react-router-dom';

import {
  Navbar,
  Nav,
  Row,
  NavDropdown,
  Col,
  Image,
} from 'react-bootstrap';
import { setMeAction } from '../redux/actions';

import {createBrowserHistory} from 'history';

import { FaClipboardList, FaUsers, FaBuilding, FaBoxOpen, FaBullseye, FaSchool } from "react-icons/fa";
import { FcSurvey } from "react-icons/fc";

import { IconContext } from "react-icons";
import './styles/Navigation.sass'
import { connect } from 'react-redux';
import ProtectedRoute from './protectedRoute.js'
import { removeToken } from '../helpers/storage.js'
import {Me} from "../types";
import Activate from "./pages/Activate";
import CustomAnalytics from "./pages/Analytics/Analytics";
import Services from "./pages/services/Services";

export const history: any = createBrowserHistory({forceRefresh:true});

async function logOut() {
  // remove me info from state
  removeToken()
  store.dispatch(setMeAction(null, false));
}

interface NavigationProps {
  me: Me;
}

class Navigation extends React.Component<NavigationProps, any> {

  async componentDidMount() {


  }

  render() {
    let { me } = this.props;
    // const { me } = this.props;
    // const title = me == null ? 'Login' : me['name'];
    return (
      <Router>
        <Navbar className="navBar" bg="light" variant="light" ref={(element: any) => {if (element) element.style.setProperty('background-color', 'white', 'important')}}>
          <Row className="mainNavContainer">
            <Navbar.Brand href="/">
              <img
                src="https://motivy-test-public.s3.us-east-2.amazonaws.com/logo.jpeg"
                width="30"
                height="30"
                className="d-inline-block align-top"
                alt="Motivy logo"
              />
            </Navbar.Brand>
          { me == null && (
            <>
              <Nav className="ml-auto">
                <Link to="/login" className="nav-link">Login</Link>
              </Nav>
            </>
          )}
          { me != null && (
            <>
              <Nav activeKey={history.location.pathname}>
                <IconContext.Provider value={{ color: "#757575", size: "23px" }}>
                <Nav.Item>
                    <Nav.Link href="/orders">
                      <Col>
                        <Row className="navIconRow">
                          <FaClipboardList className="navIcon"/>
                        </Row>
                        <Row>
                          Orders
                        </Row>
                      </Col>
                    </Nav.Link>
                  </Nav.Item>
                  </IconContext.Provider>
                  <IconContext.Provider value={{ color: "#757575", size: "23px" }}>
                	<Nav.Item>
                    <Nav.Link href="/publicity">
                      <Col>
                        <Row className="navIconRow">
                          <FaClipboardList className="navIcon"/>
                        </Row>
                        <Row>
                          Publicity
                        </Row>
                      </Col>
                    </Nav.Link>
                  </Nav.Item>
                  </IconContext.Provider>
                  <IconContext.Provider value={{ color: "#757575", size: "23px" }}>                
                  <Nav.Item>
                    <Nav.Link href="/products">
                      <Col>
                        <Row className="navIconRow">
                          <FaBoxOpen className="navIcon"/>
                        </Row>
                        <Row>
                          Products
                        </Row>
                      </Col>
                    </Nav.Link>
                  </Nav.Item>  
                  </IconContext.Provider>
                  <IconContext.Provider value={{ color: "#757575", size: "23px" }}>                
                  <Nav.Item>
                    <Nav.Link href="/clients">
                      <Col>
                        <Row className="navIconRow">
                          <FaBuilding className="navIcon"/>
                        </Row>
                        <Row>
                          Clients
                        </Row>
                      </Col>
                    </Nav.Link>
                  </Nav.Item>  
                  </IconContext.Provider>
                  <IconContext.Provider value={{ color: "#757575", size: "23px" }}>                
                  <Nav.Item>
                    <Nav.Link href="/users">
                      <Col>
                        <Row className="navIconRow">
                          <FaUsers className="navIcon"/>
                        </Row>
                        <Row>
                          Users
                        </Row>
                      </Col>
                    </Nav.Link>
                  </Nav.Item>  
                  </IconContext.Provider>
                  <IconContext.Provider value={{ color: "#757575", size: "23px" }}>
                  <Nav.Item>
                    <Nav.Link href="/goals">
                      <Col>
                        <Row className="navIconRow">
                          <FaBullseye className="navIcon"/>
                        </Row>
                        <Row>
                          Goals
                        </Row>
                      </Col>
                    </Nav.Link>
                  </Nav.Item>
                  </IconContext.Provider>
                  <IconContext.Provider value={{ color: "#757575", size: "23px" }}>
                  <Nav.Item>
                    <Nav.Link href="/surveys">
                      <Col>
                        <Row className="navIconRow">
                          <FcSurvey className="navIcon"/>
                        </Row>
                        <Row>
                          Surveys
                        </Row>
                      </Col>
                    </Nav.Link>
                  </Nav.Item>
                  </IconContext.Provider>
                  <IconContext.Provider value={{ color: "#757575", size: "23px" }}>
                    <Nav.Item>
                      <Nav.Link href="/courses">
                        <Col>
                          <Row className="navIconRow">
                            <FaSchool className="navIcon"/>
                          </Row>
                          <Row>
                            Courses
                          </Row>
                        </Col>
                      </Nav.Link>
                    </Nav.Item>
                  </IconContext.Provider>
                {/* CUSTOM ANALYTICS LINK*/}
                <IconContext.Provider value={{ color: "#757575", size: "23px" }}>
                  <Nav.Item>
                    <Nav.Link href="/custom_analytics">
                      <Col>
                        <Row className="navIconRow">
                          <FaSchool className="navIcon"/>
                        </Row>
                        <Row>
                          Custom Analytics
                        </Row>
                      </Col>
                    </Nav.Link>
                  </Nav.Item>
                </IconContext.Provider>
                {/* SERVICES LINK */}
                <IconContext.Provider value={{ color: "#757575", size: "23px" }}>
                  <Nav.Item>
                    <Nav.Link href="/services">
                      <Col>
                        <Row className="navIconRow">
                          <FaSchool className="navIcon"/>
                        </Row>
                        <Row>
                          Services
                        </Row>
                      </Col>
                    </Nav.Link>
                  </Nav.Item>
                </IconContext.Provider>
                <div className="verticalSeparator">
                </div>
              </Nav>
              <Nav>
                <NavDropdown id="collasible-nav-dropdown" drop="down" title={
                    <Image className="navProfileImage" src={me.imageURL} roundedCircle />
                }>
                  <Link onClick={logOut} to="/login" className="nav-link">Logout</Link>
                </NavDropdown>
              </Nav>
            </>
          )}
          </Row>
        </Navbar>
        <Switch>
          <Route exact path="/" component={ProtectedRoute(Orders)}/>
          <Route path="/publicity" component={ProtectedRoute(Publicity)}/>
          <Route path="/products" component={ProtectedRoute(Products)}/>
          <Route path="/orders" component={ProtectedRoute(Orders)}/>
          <Route path="/clients" component={ProtectedRoute(Clients)}/>
          <Route path="/users" component={ProtectedRoute(Users)}/>
          <Route path="/goals" component={ProtectedRoute(Goals)}/>
          <Route path="/surveys" component={ProtectedRoute(Surveys)}/>
          <Route path="/courses" component={ProtectedRoute(Courses)}/>
          <Route path="/custom_analytics" component={ProtectedRoute(CustomAnalytics)}/>
          <Route path="/services" component={ProtectedRoute(Services)}/>
          <Route path="/login">
            <Login />
          </Route>
          <Route exact path="/activate" component={Activate} />
          <Route path="/recover/password">
              <ForgotPassword/>
          </Route>
        </Switch>
      </Router>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    me: state.me.me,
  }
}

export default connect(mapStateToProps)(Navigation);