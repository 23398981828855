import React from 'react';
import { connect } from 'react-redux';
import { Form, Button, Alert } from 'react-bootstrap';
import '../styles/Login.sass';
import { login } from '../../service/api'
import { setToken } from '../../helpers/storage.js'
import { setMeAction } from '../../redux/actions';
import store from '../../redux/store';
import { withRouter } from 'react-router-dom';
import MotivyLogo from '../../assets/logoAndName.svg';
import {history} from '../Navigation';

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      errorMessage: null,
    };
  }

  componentWillReceiveProps(props) {
    // TODO:
    // If the user clicked a link to the login page but they are already logged in, redirect them to home
    // We are using JWT based authentication in the server side so we need to fetch key from cache and ping the server to see if it is valid

    let {me} = props;
    if (me != null) {
      history.push('/');
    }
  }

  async onSubmit(ev) {
    const { email, password } = this.state;
    ev.preventDefault();
    try {
      const data = await login(email, password)
      if (data['auth_token']) { setToken(data['auth_token']) }
      // Should we return user profile upon signin? I think so... change server
      store.dispatch(setMeAction(data['user']));
      history.push('/orders');
    } catch (error) {
      this.setState({ errorMessage: error.message });
    }
  }

  handleForgotPasswordClick() {
    history.push('/recover/password');
  }

  render() {
    const { email, password, errorMessage } = this.state;
    return (
      <div className="formContainer">
        <div style={{ height: 35 }} />
        <img className="loginLogo" src={MotivyLogo} alt="React Logo" />            
        <Form className="form" onSubmit={(ev) => this.onSubmit(ev)}>
          <div style={errorMessage == null ? { visibility: 'hidden' } : undefined}>
            <Alert variant="danger">{errorMessage}</Alert>
          </div>
          <Form.Control type="text" placeholder="Email" value={email} onChange={(e) => this.setState({ email: e.target.value })} />
          <Form.Control type="password" placeholder="Password" value={password} onChange={(e) => this.setState({ password: e.target.value })} />
          <Button className="loginButton" variant="primary" type="submit">
          Sign In
          </Button>
        </Form>
        <span className="pointer mb-xl lightPrimary" onClick={this.handleForgotPasswordClick.bind(this)}>Forgot password?</span>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  me: state.me.me,
});

export default withRouter(connect(mapStateToProps)(Login));
