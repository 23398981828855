import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import IconButton from "@mui/material/IconButton";
import ListIcon from '@mui/icons-material/List';
import {archiveForm} from "../../../../service/api";
import CreateForm from "./CreateForm";

interface  MenuItemProps {
    id: number;
}
export default function FadeMenu(id: MenuItemProps) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleArchived = async () => {
        await archiveForm({form_id: id})
        handleClose()
    }

    return (
        <div>
            <IconButton
                id="fade-button"
                aria-controls={open ? 'fade-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                <ListIcon/>
            </IconButton>
            <Menu
                MenuListProps={{
                    'aria-labelledby': 'fade-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                TransitionComponent={Fade}
            >
                <MenuItem>
                    <CreateForm editable={true} id={id.id}/>
                </MenuItem>
                <MenuItem onClick={handleArchived}>ARCHIVAR</MenuItem>
            </Menu>
        </div>
    );
}