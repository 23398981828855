import React from 'react';
import { FaEllipsisV } from 'react-icons/fa';
import {Col, NavDropdown, Row} from "react-bootstrap";
import {Scrollbars} from "react-custom-scrollbars";
import {connect} from "react-redux";
import NewTyCModal from "../../modals/NewTyCModal";
import '../../styles/CategoriesTab.sass';

class TyCTab extends React.Component{

    constructor(props) {
        super(props)
        this.state = {
            showEdit: false,
            editTyC: {}
        }
        this.renderTyC = this.renderTyC.bind(this);
        this.handleHideEdit = this.handleHideEdit.bind(this)
    }

    renderTyC = () => {
        const {termConditions} = this.props;
        return termConditions.map((tycond, index) => {
            return(
                <Row key={index} className="zeroMargin productsTableRow">
                    <Col className="categoriesIDCol pl-z pr-s">
                        {tycond.id}
                    </Col>
                    <Col className="categoriesNameCol alignCenter pl-z pr-s">
                        {tycond.name}
                    </Col>
                    <Col className="categoriesNameCol alignCenter pl-z pr-s">
                        <div
                            dangerouslySetInnerHTML={{__html: tycond.tyc}}
                        />
                    </Col>
                    <Col className="categoriesMenuCol pl-z pr-s">
                        <NavDropdown id={index} className="productsTableDropDown" drop="down" title={
                            <FaEllipsisV className="hCentered"/>
                        }>
                            <Row className="ml-l pointer mb-s">
                                <span onClick={this.handleEditClick.bind(this, tycond)}>Edit</span>
                            </Row>
                        </NavDropdown>
                    </Col>
                </Row>
            );
        })
    }

    handleEditClick = (tycond) => {
        this.setState({showEdit: true, editTyC: tycond})
    }

    handleHideEdit = () => {
        this.setState({showEdit: false, editTyc:{}})
    }
    render() {
        const {editTyC, showEdit} = this.state;
        return(
            <div className="categoriesContainer">
                <NewTyCModal edit={true} _show={showEdit} _handleClose={this.handleHideEdit} tyCond={editTyC}/>
                <NewTyCModal />
                <div className="categoriesTableContainer">
                    <Row className="zeroMargin productsTableTitleRow subtitle">
                        <Col className="categoriesIDCol pl-z pr-s">
                            ID
                        </Col>
                        <Col className="categoriesNameCol alignCenter pl-z pr-s">
                            Name
                        </Col>
                        <Col className="categoriesNameCol alignCenter pl-z pr-s">
                            TyC
                        </Col>
                        <Col className="categoriesMenuCol pl-z pr-s">
                            {/* menu */}
                        </Col>
                    </Row>
                    <Scrollbars className="productsScroll" >
                        {this.renderTyC()}
                    </Scrollbars>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return({
        termConditions: state.termConditions.termConditions
    });
}

export default connect(mapStateToProps)(TyCTab);