import React from 'react';
import {addTyC, editTyC, getAllTyC} from "../../service/api";
import {Button, Modal, Form, Row, Col} from "react-bootstrap";
import store from '../../redux/store';
import {setTyC} from "../../redux/actions";
import '../styles/NewCategoryModal.sass';
import RichText from "../inputs/RichText";

class NewTyCModal extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            name: '',
            tyc: '',
            isLoading: false,
        }
        this.handleShow = this.handleShow.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.handleSave = this.handleSave.bind(this)
        this.handleMessageChange = this.handleMessageChange.bind(this);
    }

    handleShow = () => {
        this.setState({show:true})
    }
    componentWillReceiveProps(props) {
        const {tyCond} = props;
        if (tyCond) this.setState({
                name: tyCond.name,
                tyc: tyCond.tyc
            })
    }
    handleClose = () => {
        this.setState({show:false})
    }

    handleMessageChange = (tyc) => {
        this.setState({...this.state, tyc:tyc})
    }

    handleSave = async () =>{
        const {name, tyc} = this.state;
        const {edit, _handleClose} = this.props;
        console.log('Esto es name', name)
        console.log('Esto es TyC', tyc)
        if(edit){
            await editTyC({name, tyc}, this.props.tyCond.id)
        }
        else {
            await addTyC({name, tyc})
        }
        const termConditions = await getAllTyC()
        store.dispatch(setTyC(termConditions));
        if (edit) _handleClose()
        else this.handleClose()
    }

    render(){
        const { show, name, tyc } = this.state
        const { edit, _show, _handleClose } = this.props;
        return(
            <>{edit !== true &&
                <span
                    className="ml-auto mb-l newCategoryModalShow pointer"
                    onClick={this.handleShow}
                >Create New Terms Condition</span>
            }
            <Modal
                show={edit === true ? _show : show}
                onHide={edit === true ? _handleClose : this.handleClose}
                centered={true}
            >
                <div className={"newCategoryModalContainer"}>
                    <Row className="zeroMargin">
                        <Col className="pl-z pr-m">
                            <Row className="zeroMargin subtitle">
                                Name:
                            </Row>
                            <Row className="zeroMargin mb-m">
                                <Form.Control
                                    type="text"
                                    placeholder=""
                                    value={name}
                                    onChange={(e) => this.setState({ name: e.target.value })} />
                            </Row>
                        </Col>
                    </Row>
                    <Row >
                        <Col>
                            <RichText
                                content={tyc}
                                onContentChange={this.handleMessageChange}
                            />
                        </Col>
                    </Row>
                    <Row className="mt-l ml-z mr-xxl">
                        <Button
                            className="ml-auto mr-m"
                            onClick={this.handleSave}>Save</Button>
                    </Row>
                </div>

            </Modal>
            </>
        );
    }
}

export default (NewTyCModal)