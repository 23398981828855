import { getUploadPresign } from '../service/api'
import axios from 'axios'

export const upload_file = async (type, data, file) => {
    // data must contain:
    // always : { file_name }
    // task upload : task_id
    data['file_name'] = data['file_name']?.replace(/\s/g, '');
    // get pre-signed url from server
    const presign_url = await getUploadPresign(type, data)
    // prepare post FORM with AWS security credentials
    const formData = new FormData();
    Object.keys(presign_url.fields).forEach(key => {
      formData.append(key, presign_url.fields[key]);
    });
    // append actual file to POST form
    formData.append("file", file);
    // ship it home
    const res = await axios.post(presign_url['url'], formData, { headers: {'Content-Type': file.type}})
    if (res['status'] === 204) {
      return { key: presign_url['fields']['key'], 'url':presign_url["url"] }
    }
}