import React from 'react';
import Navigation from './components/Navigation';
import './App.css';
import { getMe } from './service/api';
import store from './redux/store';
import { setMeAction } from './redux/actions';

import 'semantic-ui-css/semantic.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import {ReactQueryDevtools} from '@tanstack/react-query-devtools';


class App extends React.Component {
    async componentDidMount() {
        try {
            const user = await getMe();
            if (user === undefined) throw Error('No user')
            store.dispatch(setMeAction(user, true));
          } catch {
            store.dispatch(setMeAction(null, true));
          }
    }

    render() {
      return (
        <div className="AppContainer">
            <QueryClientProvider client={new QueryClient()}>
                <Navigation />
            <ReactQueryDevtools initialIsOpen={false} position={'bottom-right'}/>
            </QueryClientProvider>
        </div>
      );
    }
  }

export default App;
