import React, {useState} from "react";
import { Spinner, Form, Button } from "react-bootstrap";
import { Radio } from "semantic-ui-react";
import { IoMdClose } from "react-icons/io";
import { IconContext } from "react-icons";


interface CourseEditSwitchProps {
    editable: boolean;
    data: any;
    handleAction: any;
    save: any;
}

const CourseEditSwitch = ({ editable, data, handleAction, save }: CourseEditSwitchProps) => {
    const [loading, setLoading] = useState(false)
    const [url, setUrl] = useState(data.url || "")
    const [resultsUrl, setResultsUrl] = useState(data.results_url || "")
    const [edit, setEdit] = useState(false)


    const patron = /\/to\/([^/]+)$/;
    const editUrl = url.match(patron);

    const handleChangeActivateState = (): any => {
        setLoading( true );
        handleAction(data.id);
        setLoading( false );
    }

    const handleSave = () => {
        save( editUrl !== null ? editUrl?.[1] : url, resultsUrl);
        setEdit( false );
    }

    return (
        <div
            style={{ display: "flex", alignItems: "center" }}
            className="zeroMargin optionSwitchRow mb-m"
        >
            <span className="vCenteredParent ml-m mr-auto">{data.name}</span>
            {!edit && (
                <>
                    <span className="ml-l mr-l">{data.url || ""}</span>
                    {data.results_url && (
                        <Button
                            href={data.results_url}
                            target="_blank"
                            className="primaryLightButton mr-l"
                            style={{ marginLeft: 10 }}
                        >
                            View results
                        </Button>
                    )}
                </>
            )}
            {editable && edit && (
                <>
                    <Form.Control
                        style={{ width: "40%", marginLeft: 3 }}
                        type="text"
                        value={resultsUrl}
                        onChange={(e) => setResultsUrl( e.target.value )}
                        placeholder="Results url"
                    />
                    <Form.Control
                        style={{ width: "60%", marginLeft: 3 }}
                        type="text"
                        value={url}
                        onChange={(e) => setUrl( e.target.value )}
                        placeholder="curse url"
                    />
                </>
            )}
            {editable && !edit && (
                <span
                    className="mr-xxl lightPrimary pointer"
                    onClick={() => setEdit( true )}
                >
                    Edit Url
                </span>
            )}
            {editable && edit && (
                <>
                    <Button
                        className="primaryLightButton"
                        style={{ height: "80%", paddingTop: 2, marginLeft: 15 }}
                        onClick={handleSave}
                    >
                        Save
                    </Button>
                    <IconContext.Provider value={{ size: "20px" }}>
                        <span
                            style={{ marginRight: 3 }}
                            className="pointer ml-m title"
                            onClick={() => {
                                setEdit( false ); setUrl( data.url )
                            }}
                        >
                            <IoMdClose />
                        </span>
                    </IconContext.Provider>
                </>
            )}
            {loading && (
                <Spinner className="mr-m" size="sm" animation="grow" variant="dark" />
            )}
            {!loading && !edit && (
                <Radio
                    className="mt-s mr-m"
                    toggle
                    checked={data.is_active}
                    onClick={handleChangeActivateState}
                />
            )}
        </div>
    );
}

export default CourseEditSwitch;
