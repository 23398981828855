import React from "react";
import {Paper, Stack, Typography} from "@mui/material";
import CreateForm from "./CreateForm";
import ListForms from "./ListForms";
import {useGetForms} from "./hooks/manageData";

export function ManageForms() {

    const { forms } = useGetForms();

    return (
        <Stack width={'100%'}>
        <Paper elevation={2} sx={{p:2, flexDirection:'column', display:'flex', width:600, alignSelf:'center' }}>
            <Typography variant={'h4'}>
                Adminstrar Formulários
            </Typography>
            <Stack>
                <CreateForm/>
                <ListForms Forms={forms} />
            </Stack>
        </Paper>
        </Stack>
    )
}