import {
    Button,
    Dialog, DialogActions, DialogContent,
    Paper,
    Select, Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, TextField, Typography, Autocomplete, TablePagination, Tooltip, Checkbox
} from "@mui/material";
import React, {FC, useCallback, useEffect, useState} from "react"
import MenuItem from "@mui/material/MenuItem";
import {refundItemOrder, setOrderStatus, getAllOrders} from "../../../service/api";
import {ShowOrderInfo} from "./ShowOrderInfo";
import { useQuery } from '@tanstack/react-query'
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

interface RenderOrdersProps {
    fetchFunc: (page:number,items:number,status:string,keyword:string, product_id:number) => Promise<any>;
    type: "open" | "closed";
}

interface Item {
    date: string;
    id: number;
    order_id: number;
    product_name: string;
    quantity: number;
    status: string;
    total: number;
    user_email: string;
    user_name: string;
    user_id: number;
    fullUser: any;
    fullProduct: any;
}

interface SelectProps {
    status: string;
    id: number;
    user_id: number;
    total: number;
}

const stateConditions = {
    open: ["in-progress", "shipped"],
    closed: ["delivered", "partial-refunded","refunded"],
};

const SelectStatus:FC<SelectProps> = ({ status, id ,user_id,total }) => {
    const [value, setValue] = useState(status)
    const [sendNotification, setSendNotification] = useState(true)
    const [open, setOpen] = useState(false)

    const handleChange = async (e: any) => {
        setValue(e.target.value as string)
        if (e.target.value !== "refunded") {
            await setOrderStatus({status: e.target.value, notify:sendNotification}, id)
        }else {
            setOpen(true)
        }
    }

    const handleRefund=async () => {
        await refundItemOrder({
            "id_user": user_id,
            "points": total,
        })
        await setOrderStatus({status: "refunded", notify:sendNotification}, id)
        setOpen(false)
    }
    return (
        <>
            <Dialog open={open} onClose={()=>setOpen(false)}>
                <DialogContent>
                    <Typography variant={'subtitle2'}>Confirmar reembolso por: {total} puntos</Typography>
                </DialogContent>
                <DialogActions>
                    <Stack direction={'row'} spacing={2}>
                        <Button variant={'contained'} onClick={handleRefund}>Confirmar</Button>
                        <Button onClick={()=>setOpen(false)}>Cancelar</Button>
                    </Stack>
                </DialogActions>
            </Dialog>
            <Stack direction={'row'} alignItems={'center'}>
                <Tooltip title={"Send Notification"}>
                    <Checkbox
                        checked={sendNotification}
                        onChange={(e) => setSendNotification(e.target.checked)}
                    />
                </Tooltip>
                <Select
                    size="small"
                    value={value}
                    sx={{ minWidth: 120 }}
                    onChange={handleChange}
                >
                    <MenuItem value={'in-progress'}>In-progress</MenuItem>
                    <MenuItem value={'shipped'}>Shipped</MenuItem>
                    <MenuItem value={'delivered'}>Delivered</MenuItem>
                    <MenuItem value={'refunded'}>Refund</MenuItem>
                </Select>
            </Stack>

        </>
    )
}

const columns = [
    { id: 'order_id', label: 'Order ID', minWidth: 100 },
    { id: 'date', label: 'Date', minWidth: 100 },
    { id: 'user_email', label: 'User Email', minWidth: 100 },
    { id: 'user_name', label: 'User Name', minWidth: 100 },
    { id: 'org', label: 'Org', minWidth: 100 },
    { id: 'product_name', label: 'Product Name', minWidth: 100 },
    { id: 'quantity', label: 'Quantity', minWidth: 100 },
    { id: 'status', label: 'Status', minWidth: 100 },
    { id: 'total', label: 'Total', minWidth: 100 },
];
export const RenderOrders : FC<RenderOrdersProps>= ({ fetchFunc, type }) => {

    const [orders, setOrders] = useState<Item[]>([])
    const [ordersCsv, setOrdersCsv] = useState<Item[]>([])
    const [isLoadingData, setIsLoadingData] = useState(true)
    const [keyword, setkeyword] = useState('')
    const [filteredProductId, setFilteredProductId] = useState<any>(null);

    const {data: allOrders, isLoading: allOrdersLoading} = useQuery(['all_data_orders'], {
        queryFn: () => getAllOrders({status: type})
    })


    const [productName, setProductName] = useState<Item[]>([])

    let conjuntoDeIdsUnicos: Set<number> = new Set();

    let arrayDeOrdenesSinDuplicados: any = productName.filter((orden: any) => {
        if (!conjuntoDeIdsUnicos.has(orden?.fullProduct?.id)) {
          conjuntoDeIdsUnicos.add(orden?.fullProduct?.id);
          return true; 
        } else {
          return false; 
        }
      });
      
    const [page, setPage] = useState(0)
    const [hasNextPage, setHasNextPage] = useState(true)
    const [loadPage, setLoadPage] = useState(true)

    const getData = useCallback(
        (orders: any[]) => {
            const rows:any = []
            orders.forEach((order: any) => {
                (order.items || []).forEach(
                    (item: any) => {
                        const row : Item= {
                            id: item?.id,
                            product_name: item?.product?.name,
                            quantity: item?.amount,
                            total: item?.price * item?.amount,
                            status: item?.status,
                            date: order?.created_at,
                            order_id: order?.id,
                            user_email: order?.user.emails[0],
                            user_name: order?.user?.name?.split(' ')[0] + ' ' + order?.user?.lastname?.split(' ')[0],
                            user_id: order?.user.id,
                            fullUser: order?.user,
                            fullProduct: item?.product
                        }
                        if (type && stateConditions[type]?.includes(item?.status)) {
                            rows.push(row);
                        }
                    }
                )
            })
            return rows
        },[type])


    useEffect(() => {
        fetchFunc(page+1,10,type,keyword, filteredProductId).then((response) => {
            setLoadPage(false)
            const filteredData = getData(response.data).filter((item:any) =>
                item.order_id.toString().includes(keyword) &&
                (filteredProductId === null || item.fullProduct.id === filteredProductId)
            );
            /* setOrders(filteredData); */
            setOrders(filteredData)
            setHasNextPage(response.next);
            if(response.success === true) setIsLoadingData(false);
        });
    }, [page, fetchFunc, getData, type, keyword, filteredProductId]);

    useEffect(() => {
        if(!allOrdersLoading){
            setOrdersCsv(prevState => [...prevState, ...getData(allOrders)])
            setProductName(prevState => [...prevState, ...getData(allOrders)])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allOrders, getData, !allOrdersLoading])

    const handleChange = (e:React.ChangeEvent<HTMLInputElement>) =>{
        setkeyword(e.target.value)
    }


    async function transformTableDataToColumnsAndRows(data: any[]) {

        const rowsCsvPromises = data?.map(async (row: any, index: number) => {
        
            try {
                return {
                    id: row.order_id,
                    date: row.date,
                    user_email: row.user_email,
                    user_name: row?.user_name,
                    product_name: row?.product_name,
                    quantity: row?.quantity,
                    total: row.total
                };

            } catch (error) {
                console.error("Error fetching form responses:", error);
            return null;
            }
        });
        
        const rowsCsv = await Promise.all(rowsCsvPromises);
        
        return rowsCsv.filter((row) => row !== null);
    }

    // Function to convert data to CSV format
    function convertToCSV(data: any[]) {
        const header = Object.keys(data?.[0]).join(',') + '\n';
        const rows = data.map(obj => {
            return Object.values(obj).map(value => {
                if (typeof value === 'object' && value !== null) {
                    return JSON.stringify(value);
                }
                return String(value);
            }).join(',');
        }).join('\n');
        return header + rows;
    }

    // Function to trigger CSV download
    const downloadCSV = async (data: any[], filename: string) => {
        try {
          const rowsCsv = await transformTableDataToColumnsAndRows(data);
          const csv = convertToCSV(rowsCsv);
          const csvBlob = new Blob([csv], { type: 'text/csv' });
          // Create a temporary anchor element
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(csvBlob);
          link.setAttribute('download', filename + '.csv');
      
          // Trigger the download
          document.body.appendChild(link);
          link.click();
      
          // Cleanup
          document.body.removeChild(link);
        } catch (error) {
          console.error("Error downloading CSV:", error);
          // Manejar el error, si es necesario
        }
      };

      const [selectedProduct, setSelectedProduct] = useState<any>(null);

      const handleFilterChange = (productId: number) => {
        setFilteredProductId(productId);
      };

      const handleProductChange = (event: React.SyntheticEvent, value: any) => {
        if(value === null){
            setFilteredProductId(null)
        }
        setSelectedProduct(value);
      };

      const handleFilterClick = () => {
        if (selectedProduct) {
            handleFilterChange(selectedProduct.fullProduct?.id);
        }
      };


      const [rowsPerPage, setRowsPerPage] = useState(10);

      const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
        setLoadPage(true)
      };
    
      const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
      };
    

    return (
        <>
        <TableContainer component={Paper}>
            <Stack
                spacing={2}
                direction={"row"}
                paddingTop={'5px'}
            >
                <Button
                    variant="contained"
                    onClick={() => downloadCSV(!allOrdersLoading && ordersCsv ? ordersCsv : [{"empty":1}], "orders")}
                    sx={{
                        maxWidth: 200,
                        width: 'fit-content',
                        maxHeight:50,
                        bgcolor: 'rgb(242, 114, 125)',
                        '&:hover': { bgcolor: 'rgb(242, 114, 125, 0.90)' }
                    }}
                    disabled={isLoadingData || allOrdersLoading}
                >
                    <Stack direction="row" spacing={1} alignItems="center">
                        <Typography>Descargar CSV {allOrdersLoading && ' cargando...'}</Typography>
                    </Stack>
                </Button>
                <TextField
                    id="outlined-controlled"
                    label="Buscar por Order id"
                    value={keyword}
                    onChange={(e:any) => {
                        handleChange(e);
                    }}
                />
                <div>
                    <Autocomplete
                        options={arrayDeOrdenesSinDuplicados}
                        getOptionLabel={(order) => order?.product_name}
                        onChange={handleProductChange}
                        renderInput={(params) => (
                        <TextField {...params} label="Select Product" variant="outlined" />
                        )}
                        sx={{width: '300px'}}
                        disabled={allOrdersLoading}
                    />
                    <Button variant="contained" onClick={handleFilterClick} disabled={allOrdersLoading}>
                        Filter
                    </Button>
                </div>
            </Stack>
                
            <Table>

                <TableHead>
                    <TableRow>
                        {columns.map((column) => (
                            <TableCell
                                sx={{fontWeight:'bold'}}
                                key={column.id}
                            >
                                {column.label}
                            </TableCell>
                            ))}
                    </TableRow>
                </TableHead>
                <TableBody>

                    { isLoadingData ? (
                        <Box sx={{ display: 'flex', transform: 'translateX(720px)', marginTop:1 }}>
                            <CircularProgress />
                        </Box> ) : ( orders?.map((item) => (
                        <TableRow key={item.id}>
                            <TableCell>{item.order_id}</TableCell>
                            <TableCell>{item.date.split('T')[0]}</TableCell>
                            <TableCell>{item.user_email}</TableCell>
                            <TableCell>{item.user_name}</TableCell>
                            <TableCell>{item.fullUser?.organization?.name}</TableCell>
                            <TableCell>{item.product_name}</TableCell>
                            <TableCell>{item.quantity}</TableCell>
                            <TableCell>
                                <SelectStatus
                                    id = {item.id}
                                    user_id={item.user_id}
                                    total={item.total}
                                    status={item.status}/>
                            </TableCell>
                            <TableCell>{item.total}</TableCell>
                            <TableCell>
                                <ShowOrderInfo
                                    data={item}
                                />
                            </TableCell>
                        </TableRow>
                        )
                        )) 
                    }
                </TableBody>
            </Table>
            
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[]}
          component="div"
          count={orders?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          nextIconButtonProps={{ disabled: !hasNextPage || loadPage }}
          backIconButtonProps={{disabled: loadPage}}
        />
        </>
    );
};