import React, {useEffect, useState} from 'react';
import '../../styles/Users.sass';
import {activateMultiResponseSurvey, activateSurvey, editSurvey, getOrgs, getOrgSurveys} from '../../../service/api';
import { Dropdown } from 'semantic-ui-react';
import { Scrollbars } from 'react-custom-scrollbars';
import NewSurveyModal from '../../modals/NewSurveyModal'
import SurveyEditSwitch from '../../SurveyEditSwitch';
import {Col, Row } from 'react-bootstrap';
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';


export interface Survey {
    id: any;
    name: string;
    url: string;
    is_active: boolean;
    results_url?: string;
    is_multi_response?: boolean;
}

const Surveys = () => {
    const [surveys, setSurveys] = useState([])
    const [orgs, setOrgs] = useState([] as any [])
    const [currentOrg, setCurrentOrg] = useState(null)

    useEffect( () => {
        const start = async () => {
            setOrgs( await getOrgs() )
        }
        start()
    }, [])

    const getOrgOptions = () => {
        return orgs.map((org, index) => {
            return {
                key: index,
                text: org.name,
                value: org.id
            }
        })
    }

    const handleOrgChange = (e: any, {value}: any) => {
        if (currentOrg === value) return
        setCurrentOrg( value )
        setSurveys([])
        fetchSurveys(value)
    }

    const fetchSurveys = (value: any) => {
        setTimeout(() => {
            if (!value) {
                value = currentOrg
            }
            getOrgSurveys(value).then(surveys => setSurveys(surveys))
        }, 200)
    }

    const refreshSurveys = async () => {
        if ( currentOrg ){
            const surveyData = await getOrgSurveys( currentOrg )
            setSurveys( surveyData )
        }
    }

    const renderSurveys = () => {
        return surveys.map( (survey: Survey) => (
            <SurveyEditSwitch
                key={survey.id}
                data={survey}
                handleAction={ async (id: number) => {
                    await activateSurvey( id )
                    refreshSurveys()
                } }
                handleMultiResponse={async (id: number) => {
                    await activateMultiResponseSurvey( id )
                    refreshSurveys()
                }}
                save={ async (url: string, resultsUrl: string) => {
                    await editSurvey(survey.id, { url, results_url: resultsUrl })
                    refreshSurveys()
                } }
            />
        ) )
    }

    return(
        <div className="usersContainer pt-m">
            <span className="subtitle">Organization:</span>
            <Dropdown
                className="mb-xl mt-s"
                selection
                fluid
                options={getOrgOptions()}
                onChange={(e, a) => handleOrgChange(e,a)}
            />
            <FormControl variant="filled">
                <InputLabel id="org-label">Organización</InputLabel>
                <Select
                    labelId="org-label"
                    id="org-select"
                    onChange={handleOrgChange}
                >
                    {getOrgOptions().map((option, index) => (
                    <MenuItem key={index} value={option.value}>
                        {option.text}
                    </MenuItem>
                    ))}
                </Select>
            </FormControl>
            { currentOrg !== null &&
                <>
                    <NewSurveyModal handleNew={fetchSurveys} organization_id={currentOrg} />
                    <Row>
                        <Col md={6}>Name</Col>
                        <Col md={2}>Results</Col>
                        <Col md={2}>URL</Col>
                        <Col md={1}>Active</Col>
                        <Col md={1}>Multi Response</Col>
                    </Row>
                    <div className="usersTableContainer mt-xl">
                        <Scrollbars className="clientsScroll" >
                            {renderSurveys()}
                        </Scrollbars>
                    </div>
                </>
            }
            { currentOrg === null &&
                <span className="ml-auto mr-auto mt-xxl title">Please select an org to see its users</span>
            }
        </div>
    )
}

export default (Surveys);
