import  React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    Icon,
    Link,
    Stack, Table, TableCell, TableHead, TableRow,
    Typography,
} from "@mui/material";
import DescriptionIcon from '@mui/icons-material/Description';
import SaveIcon from '@mui/icons-material/Save';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import GetAppIcon from '@mui/icons-material/GetApp';

export const UploadProductusByExcel=() => {
    const [file, setFile] = React.useState<any>(null);
    const [selectedFile, setFileWasSelected_] = React.useState<boolean>(false);
    const [open, setOpen] = React.useState(false);
    const [createdProducts, setCreatedProducts] = React.useState<any>([]);
    const token = localStorage.getItem('auth_token')

    const handleOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
        setFileWasSelected_(false)
        setCreatedProducts([]);
        setFile(null)
    }
    const validateExcel =  async () => {
        const formData = new FormData()

        formData.append('products', file)


        try {
            const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/staff/upload/products`, {
                method: 'post',
                body: formData,
                headers: {Authorization: `Bearer ${token}`},
            })
            if (res.body) {
                const reader = res.body.getReader()
                while(true) {
                    let chunk = await reader.read()
                    if (chunk.value && !chunk.done) {
                        let decoded = new TextDecoder('utf-8').decode(chunk.value)
                        decoded='['+decoded.substring(0, decoded.length - 1)+']'
                        console.log(decoded)
                        let json: any = JSON.parse(decoded)
                        setCreatedProducts((c:any )=> [...c, ...json])
                    } else {
                        break
                    }
                }
            }
        } catch (error) {
            console.error('Error al obtener datos:', error);
        }
    }

    return (
        <>
            <Button
                onClick={handleOpen}
                variant={'text'}
            >
                <Typography sx={{textTransform: 'none'}}>UPLOAD PRODUCTS BY EXCEL</Typography>
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                PaperProps={{
                    sx: {maxHeight: '70vh', maxWidth: '80vw', overflow: 'auto', width: 600},
                }}
            >
                <DialogContent sx={{maxHeight: '80vh',width:600}}>
                    <Typography variant="h5">
                        Cargar productos por excel
                    </Typography>
                    {createdProducts.length===0 &&
                        <Stack justifyContent={"center"} alignItems={"center"}>
                        {
                            selectedFile ?
                                <>
                                     <DescriptionIcon/>
                                     <Typography
                                        variant="body1"
                                        color={"ButtonShadow"}
                                     >
                                        Products-by-excel
                                     </Typography>
                                    </>
                                : <>
                                    <Icon sx={{fontSize: '8rem'}} color={'disabled'}>
                                        <DescriptionIcon/>
                                    </Icon>
                                    <Typography
                                        variant="body1"
                                        color={"ActiveCaption"}
                                    >
                                        No hay archivo seleccionado
                                    </Typography>
                                </>
                        }
                    </Stack>}
                    {createdProducts.length>0 &&
                        <Stack justifyContent={"space-between"} alignItems={"center"}>
                            <Table>
                                <TableHead>
                                    <TableRow sx={{justifyContent:'space-between'}}>
                                        <TableCell sx={{fontWeight:'bold'}}>Product</TableCell>
                                        <TableCell sx={{fontWeight:'bold'}}>State</TableCell>
                                    </TableRow>
                                </TableHead>
                                {
                                    createdProducts.map((product:any) => (
                                        <TableRow>
                                            <TableCell>
                                                <Typography
                                                    sx={{maxWidth:100, overflow:'hidden', textOverflow:'ellipsis'}}
                                                >
                                                    {product?.name}
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography
                                                    sx={{maxWidth:200, textOverflow:'ellipsis'}}
                                                    color={product?.message[0].includes('Not') ? 'red' : 'green'}
                                                >
                                                    {product?.message}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                }
                            </Table>
                        </Stack>
                    }

                </DialogContent>
                {createdProducts.length===0 && <DialogActions>
                    <Button variant={'text'} component={'label'} startIcon={<FileUploadIcon/>}>
                        <Typography sx={{textTransform: 'none'}}>Subir Archivo</Typography>
                        <input
                            onChange={(e) => {
                                const files = e.target.files
                                let file = null
                                if (files) {
                                    file = files[0]
                                }
                                setFileWasSelected_(true)
                                setFile(file)
                            }}
                            type={"file"}
                            hidden
                            accept={"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"}/>
                    </Button>
                    <Link
                        href={`https://motivy-prod-public.s3.amazonaws.com/products/Documents/Plantilla%2Bcarga%2Bmasiva%2Bde%2Bproductos%2BMotivy.xlsx`}
                        underline={"none"}
                        download={"recognitions-by-email"}
                    >
                        <Stack direction={"row"}>
                            <GetAppIcon/>
                            <Typography component={'span'}>Plantilla</Typography>
                        </Stack>
                    </Link>
                </DialogActions>}
                {selectedFile && createdProducts.length===0  &&
                    <Button variant="contained"  onClick={validateExcel} startIcon={<SaveIcon/>}>Guardar</Button>}
            </Dialog>
        </>
    )
}