import { SET_PRODUCTS } from './actions';

const INITIAL_STATE = {
  products: [],
};

export default function productsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_PRODUCTS:
      return { ...state, products: action.products };
    default:
      return state;
  }
}
